var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Iz,Kz,Lz,Mz,Oz,Sz,Tz,Uz,Vz,Wz,Zca,Zz,$ca,cA,dA,eA,fA,gA,hA,iA,jA,oA,rA,tA,wA,ada,bda,BA,cda,DA,EA,FA,LA,MA,UA,YA,dB,fB,kB,oB,tB,vB,wB,qB,yB,zB,BB,AB,CB,gda,HB,QB,RB,KB,ida,VB,YB,$B,aC,kda,lda,mda,eC,iC,jC,kC,lC,nda,pC,sC,oda,pda,qda,rda,sda,tda,uda,vda,wda,yda,yC,xda,CC,zda,DC,Ada,Bda,EC,Cda,FC,Dda,Eda,Fda,Hda,Ida,Kda,Lda,Nda,Pda,KC,NC,Rda,Qda,Sda,pB,sB,UC,VC,WC,XC,$C,aD,Vda,Wda,Xda,dD,Yda,eD,cD,mA,gD,AA,rC,Zda,hB,$da,aea,pA,yA,YC,bea,WA,GB,GA,cea,dea,TA,eea,LC,KA,FB,RA,uC,fea,zA,
gea,BC,IC,hD,hea,bC,jD,dC,VA,EB,lA,HC,fda,gB,PA,sA,iea,xB,jea,NB,cC,PC,kea,RC,eda,lea,mea,nea,lB,oea,zC,jB,pea,OB,MB,qea,uA,rea,Uda,SC,HA,lD,gC,mD,nD,SB,sea,JA,tea,Tda,uea,nB,xC,DB,vea,hC,ZC,wea,wC,xea,LB,xA,yea,XA,pD,fC,zea,qA,GC,iB,dda,JC,vA,mC,MC,qC,nC,oC,AC,TB,qD,rD,Aea,Bea,bA,kA,Cea,IA,Dea;Iz=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Jz=function(){};
Kz=function(a){if(null!=a&&null!=a.Sc)a=a.Sc(a);else{var b=Kz[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Kz._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IWriter.-flush",a);}return a};Lz=function(){};Mz=function(a,b){return a-b*$CLJS.af(a,b)};$CLJS.Nz=function(a){if(null==$CLJS.Ab)throw Error("No *print-fn* fn set for evaluation environment");$CLJS.Ab.call(null,a);return null};
Oz=function(a,b,c){if(null!=a&&null!=a.o)a.o(a,b,c);else{var d=Oz[$CLJS.Na(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Oz._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Pb("IMultiFn.-add-method",a);}};$CLJS.Pz=function(a,b){var c=$CLJS.Tc($CLJS.P);a=$CLJS.A(a);for(b=$CLJS.A(b);;)if(a&&b)c=$CLJS.rh.j(c,$CLJS.C(a),$CLJS.C(b)),a=$CLJS.D(a),b=$CLJS.D(b);else return $CLJS.Vc(c)};
Sz=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.R.h(Qz,0<b.length?new $CLJS.z(b.slice(0),0,null):null);$CLJS.Qc($CLJS.Rz,"\n")};Tz=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Uz=function(a,b,c){var d=c;for(c=$CLJS.Lg;;){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null);var e=$CLJS.C(d);d=$CLJS.D(d);e=$CLJS.R.h(a,new $CLJS.S(null,2,5,$CLJS.T,[e,b],null));b=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);c=$CLJS.kf.h(c,b);b=e}};Vz=function(a,b){var c=b;for(b=$CLJS.Lg;;){var d=$CLJS.R.h(a,new $CLJS.S(null,1,5,$CLJS.T,[c],null));c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.Gb(c))return new $CLJS.S(null,2,5,$CLJS.T,[b,d],null);b=$CLJS.kf.h(b,c);c=d}};
Wz=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);var v=$CLJS.J(q,0,null);$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[u,v],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.J(f,0,null);$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,g],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}()),$CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())],null)};
Zca=function(a,b){return $CLJS.oh.h($CLJS.P,function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);n.add(new $CLJS.S(null,2,5,$CLJS.T,[v,new $CLJS.S(null,2,5,$CLJS.T,[u,b],null)],null));q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);
g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[n,new $CLJS.S(null,2,5,$CLJS.T,[g,b],null)],null),e($CLJS.zd(f)))}return null}},null,null)}(a)}())};$CLJS.Xz=function(){var a=$CLJS.Rz;if(null!=a&&null!=a.ig)a.ig(a);else{var b=$CLJS.Xz[$CLJS.Na(null==a?null:a)];if(null!=b)b.g?b.g(a):b.call(null,a);else if(b=$CLJS.Xz._,null!=b)b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IPrettyFlush.-ppflush",a);}};$CLJS.Yz=function(a,b){a=$CLJS.r($CLJS.r(a));return b.g?b.g(a):b.call(null,a)};
Zz=function(a,b,c){$CLJS.dj.D($CLJS.r(a),$CLJS.U,b,c)};$ca=function(a,b){$CLJS.F.h(b,"\n")?(Zz(a,$CLJS.$z,0),Zz(a,$CLJS.Xk,$CLJS.Yz(a,$CLJS.Xk)+1)):Zz(a,$CLJS.$z,$CLJS.Yz(a,$CLJS.$z)+1);return $CLJS.Qc($CLJS.Yz(a,bA),b)};cA=function(a,b,c,d){this.La=a;this.Bg=b;this.Hd=c;this.Vi=d;this.C=1074167808;this.K=0};
dA=function(a,b,c,d,e,f,g,l,n,q,u,v,x){this.parent=a;this.Mb=b;this.Eb=c;this.Bb=d;this.Gb=e;this.Hb=f;this.prefix=g;this.Lb=l;this.Nb=n;this.Ib=q;this.F=u;this.m=v;this.G=x;this.C=2230716170;this.K=139264};eA=function(a,b){for(b=$CLJS.av.g(b);;){if(null==b)return!1;if(a===b)return!0;b=$CLJS.av.g(b)}};fA=function(a,b,c,d,e,f,g,l){this.ja=a;this.data=b;this.wc=c;this.ia=d;this.ha=e;this.F=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};
gA=function(a,b,c,d,e,f,g,l){this.ja=a;this.type=b;this.la=c;this.ia=d;this.ha=e;this.F=f;this.m=g;this.G=l;this.C=2230716170;this.K=139264};hA=function(a,b,c,d,e,f,g){this.ja=a;this.la=b;this.ia=c;this.ha=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};iA=function(a,b,c,d,e,f,g){this.ja=a;this.la=b;this.ia=c;this.ha=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
jA=function(a,b,c,d,e,f,g,l,n){this.ja=a;this.la=b;this.lc=c;this.offset=d;this.ia=e;this.ha=f;this.F=g;this.m=l;this.G=n;this.C=2230716170;this.K=139264};
oA=function(a,b,c){b=$CLJS.A(b);for(var d=null,e=0,f=0;;)if(f<e){var g=d.$(null,f);if(!$CLJS.F.h(kA.g(g),lA)){var l=mA.g($CLJS.r($CLJS.r(a)));$CLJS.m(l)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),l)}nA.h(a,g);$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,mA.g(g));g=mA.g($CLJS.r($CLJS.r(a)));$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,null));f+=1}else if(b=$CLJS.A(b))$CLJS.re(b)?(d=$CLJS.$c(b),b=$CLJS.ad(b),g=d,e=$CLJS.E(d),d=g):(g=$CLJS.C(b),$CLJS.F.h(kA.g(g),lA)||
(d=mA.g($CLJS.r($CLJS.r(a))),$CLJS.m(d)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),d)),nA.h(a,g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,mA.g(g)),g=mA.g($CLJS.r($CLJS.r(a))),$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),g),$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,null)),b=$CLJS.D(b),d=null,e=0),f=0;else break};rA=function(a,b){var c=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(a))),$CLJS.Yl),d;(d=null==c)||(a=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(a))),$CLJS.$z),b=(b=$CLJS.A(b))?pA.g($CLJS.$d(b))-qA.g($CLJS.C(b)):0,d=a+b<c);return d};
tA=function(a,b,c){b=$CLJS.r(sA.g(b));return $CLJS.m(b)?b:!rA(a,c)};wA=function(a,b,c){var d=uA.g($CLJS.r($CLJS.r(a))),e=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(a))),$CLJS.Yl);return $CLJS.m(d)?$CLJS.m(e)?(d=$CLJS.r(vA.g(b))>=e-d)?tA(a,b,c):d:e:d};ada=function(a){var b=$CLJS.C(a),c=xA.g(b);b=$CLJS.A($CLJS.vi(function(d){return!($CLJS.F.h(kA.g(d),lA)&&eA(xA.g(d),c))},$CLJS.D(a)));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.kg($CLJS.E(b)+1,a))],null)};
bda=function(a){var b=$CLJS.C(a),c=xA.g(b);return $CLJS.A($CLJS.vi(function(d){var e=xA.g(d);return!($CLJS.F.h(kA.g(d),lA)&&($CLJS.F.h(e,c)||eA(e,c)))},$CLJS.D(a)))};
BA=function(a,b){$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,null);b=xA.g(b);var c=yA.g(b);$CLJS.m(c)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),c);c=$CLJS.R.h($CLJS.p,$CLJS.ng($CLJS.r(zA.g(b))-$CLJS.E(c)," "));$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),c);a:for($CLJS.gg(AA.g(b),!0),$CLJS.gg(sA.g(b),!0),a=$CLJS.av.g(b);;)if($CLJS.m(a))$CLJS.gg(sA.g(a),!0),$CLJS.gg(AA.g(a),!0),a=$CLJS.av.g(a);else break a;return null};
cda=function(a){var b=$CLJS.A($CLJS.vi(function(c){return!$CLJS.F.h(kA.g(c),lA)},a));return new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.A($CLJS.kg($CLJS.E(b),a))],null)};DA=function(a){for(var b=$CLJS.Su.g($CLJS.r($CLJS.r(a)));;){$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Su,$CLJS.oh.h($CLJS.Lg,b));if(rA(a,b))return null;var c=CA(a,b);if(b!==c)b=c;else return null}};
EA=function(a,b){$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Su,$CLJS.kf.h($CLJS.Su.g($CLJS.r($CLJS.r(a))),b));return rA(a,$CLJS.Su.g($CLJS.r($CLJS.r(a))))?null:DA(a)};FA=function(a){var b=mA.g($CLJS.r($CLJS.r(a)));return $CLJS.m(b)?($CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),b),$CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,null)):null};
LA=function(a,b){var c=$CLJS.Ou(b,"\n",-1);if($CLJS.F.h($CLJS.E(c),1))return b;b=yA.g($CLJS.C(GA.g($CLJS.r($CLJS.r(a)))));var d=$CLJS.C(c);if($CLJS.F.h(HA,$CLJS.dk.g($CLJS.r($CLJS.r(a))))){var e=IA.g($CLJS.r($CLJS.r(a))),f=e+$CLJS.E(d);$CLJS.dj.D($CLJS.r(a),$CLJS.U,IA,f);EA(a,new fA(JA,d,null,e,f,null,null,null));DA(a);d=$CLJS.Su.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(oA(a,d,!0),$CLJS.dj.D($CLJS.r(a),$CLJS.U,$CLJS.Su,$CLJS.Lg))}else FA(a),$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),d);$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),
"\n");d=$CLJS.A($CLJS.D($CLJS.ti(c)));e=null;for(var g=f=0;;)if(g<f){var l=e.$(null,g);$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),l);$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.m(b)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),b);g+=1}else if(d=$CLJS.A(d))e=d,$CLJS.re(e)?(d=$CLJS.$c(e),g=$CLJS.ad(e),e=d,f=$CLJS.E(d),d=g):(d=$CLJS.C(e),$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),d),$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),"\n"),$CLJS.m(b)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),b),d=$CLJS.D(e),e=null,f=0),g=0;else break;$CLJS.dj.D($CLJS.r(a),
$CLJS.U,HA,KA);return $CLJS.$d(c)};MA=function(a,b,c,d,e,f){this.La=a;this.Bg=b;this.fj=c;this.ei=d;this.Hd=e;this.Wi=f;this.C=1074167808;this.K=0};
$CLJS.QA=function(a){var b=$CLJS.NA,c=OA,d=new dA(null,null,$CLJS.cj.g(0),$CLJS.cj.g(0),$CLJS.cj.g(!1),$CLJS.cj.g(!1),null,null,null,null,null,null,null),e=$CLJS.cj.g,f=$CLJS.cj.g(new $CLJS.k(null,4,[$CLJS.Yl,b,$CLJS.$z,0,$CLJS.Xk,0,bA,a],null));e=e.call($CLJS.cj,$CLJS.Sh([GA,uA,dda,PA,eda,$CLJS.dk,IA,mA,bA,fda,$CLJS.Su],[d,c,d,!0,null,KA,0,null,new cA(a,b,f,$CLJS.P),1,$CLJS.Lg]));return new MA(a,b,c,d,e,$CLJS.P)};
UA=function(a,b){var c=$CLJS.Rz;b=new dA(GA.g($CLJS.r($CLJS.r(c))),null,$CLJS.cj.g(0),$CLJS.cj.g(0),$CLJS.cj.g(!1),$CLJS.cj.g(!1),a,null,b,null,null,null,null);$CLJS.dj.D($CLJS.r(c),$CLJS.U,GA,b);if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(c))),KA)){FA(c);var d=RA.g($CLJS.r($CLJS.r(c)));$CLJS.m(d)&&(d.g?d.g($CLJS.SA):d.call(null,$CLJS.SA));$CLJS.m(a)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(c))),a);c=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(c))),$CLJS.$z);$CLJS.gg(b.Eb,c);$CLJS.gg(b.Bb,c)}else d=IA.g($CLJS.r($CLJS.r(c))),
a=d+($CLJS.m(a)?$CLJS.E(a):0),$CLJS.dj.D($CLJS.r(c),$CLJS.U,IA,a),EA(c,new hA(TA,b,d,a,null,null,null))};
YA=function(){var a=$CLJS.Rz,b=GA.g($CLJS.r($CLJS.r(a))),c=VA.g(b);if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(a))),KA)){FA(a);$CLJS.m(c)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),c);var d=RA.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(d.g?d.g(WA):d.call(null,WA))}else d=IA.g($CLJS.r($CLJS.r(a))),c=d+($CLJS.m(c)?$CLJS.E(c):0),$CLJS.dj.D($CLJS.r(a),$CLJS.U,IA,c),EA(a,new iA(XA,b,d,c,null,null,null));$CLJS.dj.D($CLJS.r(a),$CLJS.U,GA,$CLJS.av.g(b))};
$CLJS.ZA=function(a){var b=null!=a?a.C&32768||$CLJS.t===a.Zf?!0:a.C?!1:$CLJS.Nb($CLJS.Jz,a):$CLJS.Nb($CLJS.Jz,a);return b?PA.g($CLJS.r($CLJS.r(a))):b};$CLJS.cB=function(a){var b=$A;$CLJS.m(b)&&(b=$CLJS.yb,b=$CLJS.m(b)?$A>=$CLJS.yb:b);$CLJS.Gb($CLJS.aB)?Qz.call(null,a):$CLJS.m(b)?$CLJS.Qc($CLJS.Rz,"..."):($CLJS.m($A)&&($A+=1),bB.call(null,a));return b};dB=function(a,b){if($CLJS.Gb(b.g?b.g(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.g(a),". It must be one of ",$CLJS.p.g(b)].join(""));};
fB=function(){var a=$CLJS.Gi;return $CLJS.m(a)?eB>=$CLJS.Gi:a};kB=function(a){dB(a,new $CLJS.ni(null,new $CLJS.k(null,4,[gB,null,hB,null,iB,null,jB,null],null),null));var b=$CLJS.Rz;$CLJS.dj.D($CLJS.r(b),$CLJS.U,$CLJS.dk,HA);var c=IA.g($CLJS.r($CLJS.r(b))),d=GA.g($CLJS.r($CLJS.r(b)));EA(b,new gA(lA,a,d,c,c,null,null,null))};
oB=function(a,b){dB(a,new $CLJS.ni(null,new $CLJS.k(null,2,[lB,null,$CLJS.mB,null],null),null));var c=$CLJS.Rz,d=GA.g($CLJS.r($CLJS.r(c)));if($CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(c))),KA)){FA(c);var e=zA.g(d);if($CLJS.m($CLJS.F.h?$CLJS.F.h(lB,a):$CLJS.F.call(null,lB,a)))a=$CLJS.r(vA.g(d));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.mB,a):$CLJS.F.call(null,$CLJS.mB,a)))a=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(c))),$CLJS.$z);else throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));$CLJS.gg(e,b+a)}else e=
IA.g($CLJS.r($CLJS.r(c))),EA(c,new jA(nB,d,a,b,e,e,null,null,null))};tB=function(a,b,c){b="string"===typeof b?pB(b):b;c=qB(c);a:{var d=new $CLJS.rb,e=$CLJS.Gb(a)||!0===a?new $CLJS.gd(d):a,f=rB(b)&&$CLJS.Gb($CLJS.ZA(e))?$CLJS.m($CLJS.ZA(e))?e:$CLJS.QA(e):e,g=$CLJS.Rz;$CLJS.Rz=f;try{try{sB(b,c)}finally{e!==f&&Kz(f)}var l=$CLJS.Gb(a)?$CLJS.p.g(d):!0===a?$CLJS.Nz($CLJS.p.g(d)):null;break a}finally{$CLJS.Rz=g}l=void 0}return l};
vB=function(a,b){a=[$CLJS.p.g(a),"\n",$CLJS.p.g(uB),"\n",$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(b," "))),"^\n"].join("");throw Error(a);};wB=function(a,b,c,d,e,f){this.Wc=a;this.xb=b;this.uc=c;this.F=d;this.m=e;this.G=f;this.C=2230716170;this.K=139264};qB=function(a){a=$CLJS.A(a);return new wB(a,a,0,null,null,null)};
yB=function(a){var b=$CLJS.xk.g(a);if($CLJS.m(b))return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),new wB(xB.g(a),$CLJS.D(b),IA.g(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};zB=function(a){var b=yB(a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);a="string"===typeof a?pB(a):a;return new $CLJS.S(null,2,5,$CLJS.T,[a,b],null)};BB=function(a,b){return b>=IA.g(a)?(b=IA.g(a)-b,AB.h?AB.h(a,b):AB.call(null,a,b)):new wB(xB.g(a),$CLJS.kg(b,xB.g(a)),b,null,null,null)};
AB=function(a,b){var c=IA.g(a)+b;return 0>b?BB(a,c):new wB(xB.g(a),$CLJS.kg(b,$CLJS.xk.g(a)),c,null,null,null)};CB=function(a,b,c,d,e,f,g){this.Ac=a;this.yc=b;this.Hc=c;this.offset=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};
gda=function(a,b){var c=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);d=$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[DB,null,EB,null],null),null),c)?new $CLJS.S(null,2,5,$CLJS.T,[d,b],null):$CLJS.F.h(d,FB)?yB(b):$CLJS.F.h(d,GB)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.E($CLJS.xk.g(b)),b],null):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.S(null,2,5,$CLJS.T,
[b,a],null)],null),d],null)};HB=function(a,b){b=Uz(gda,b,a);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oh.h($CLJS.P,a),b],null)};
QB=function(a,b,c){c=yB(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d))if($CLJS.F.h(IB,10))var e=[$CLJS.p.g(d),$CLJS.m(JB)?".":null].join("");else{e=$CLJS.p;var f=e.g;if($CLJS.m(JB)){var g=$CLJS.M.h(hda,IB);g=$CLJS.m(g)?g:["#",$CLJS.p.g(IB),"r"].join("")}else g=null;e=[f.call(e,g),$CLJS.p.g(KB(IB,d))].join("")}else e=null;a=$CLJS.m(e)?e:a.g?a.g(d):a.call(null,d);d=a.length;e=d+LB.g(b);e=e>=MB.g(b)?e:e+($CLJS.af(MB.g(b)-e-1,NB.g(b))+1)*NB.g(b);d=$CLJS.R.h($CLJS.p,$CLJS.ng(e-d,OB.g(b)));
$CLJS.m(EB.g(b))?PB.l($CLJS.G([[$CLJS.p.g(d),$CLJS.p.g(a)].join("")])):PB.l($CLJS.G([[$CLJS.p.g(a),$CLJS.p.g(d)].join("")]));return c};RB=function(a,b){return $CLJS.lf($CLJS.C(Vz(function(c){return 0<c?new $CLJS.S(null,2,5,$CLJS.T,[Mz(c,a),$CLJS.af(c,a)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,null],null)},b)))};KB=function(a,b){return 0===b?"0":$CLJS.R.h($CLJS.p,$CLJS.rg.h(function(c){return 10>c?$CLJS.Eu(Tz("0")+c):$CLJS.Eu(Tz("a")+(c-10))},RB(a,b)))};
ida=function(a,b){return $CLJS.lf($CLJS.C(Vz(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.A($CLJS.lf($CLJS.jg(a,c))),$CLJS.A($CLJS.kg(a,c))],null)},$CLJS.lf(b))))};
VB=function(a,b,c){c=yB(c);var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.Ae(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.h(d,Math.floor(d))){var e=0>d,f=KB(a,e?-d:d);a=$CLJS.m(DB.g(b))?function(){var g=$CLJS.rg.h(function(n){return $CLJS.R.h($CLJS.p,n)},ida(SB.g(b),f)),l=$CLJS.ng($CLJS.E(g),TB.g(b));return $CLJS.R.h($CLJS.p,$CLJS.D($CLJS.og.h(l,g)))}():f;a=e?["-",$CLJS.p.g(a)].join(""):$CLJS.m(EB.g(b))?["+",$CLJS.p.g(a)].join(""):a;a=a.length<MB.g(b)?
[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(MB.g(b)-a.length,OB.g(b)))),$CLJS.p.g(a)].join(""):a;PB.l($CLJS.G([a]))}else QB($CLJS.UB,new $CLJS.k(null,5,[MB,MB.g(b),NB,1,LB,0,OB,OB.g(b),EB,!0],null),qB(new $CLJS.S(null,1,5,$CLJS.T,[d],null)));return c};
YB=function(a){var b=$CLJS.af(a,100);a=Mz(a,100);var c=0<b?[$CLJS.p.g($CLJS.Td(WB,b))," hundred"].join(""):null,d=$CLJS.p,e=d.g;if(0<a)if(20>a)var f=$CLJS.Td(WB,a);else{f=$CLJS.af(a,10);var g=Mz(a,10);f=[$CLJS.p.g(0<f?$CLJS.Td(XB,f):null),0<f&&0<g?"-":null,$CLJS.p.g(0<g?$CLJS.Td(WB,g):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
$B=function(a,b){var c=$CLJS.E(a),d=$CLJS.Lg;--c;var e=$CLJS.C(a);for(a=$CLJS.D(a);;){if(null==a)return[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.pg(", ",d))),$CLJS.je(e)||$CLJS.je(d)?null:", ",$CLJS.p.g(e),!$CLJS.je(e)&&0<c+b?[" ",$CLJS.p.g($CLJS.Td(ZB,c+b))].join(""):null].join("");d=$CLJS.je(e)?d:$CLJS.kf.h(d,[$CLJS.p.g(e)," ",$CLJS.p.g($CLJS.Td(ZB,c+b))].join(""));--c;e=$CLJS.C(a);a=$CLJS.D(a)}};
aC=function(a,b){b=yB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=RB(10,c);c=$CLJS.Lg;for(var e=$CLJS.E(d)-1;;)if($CLJS.je(d)){PB.l($CLJS.G([$CLJS.R.h($CLJS.p,c)]));break}else{var f=$CLJS.C(d);c=$CLJS.F.h(0,f)?c:$CLJS.kf.h(c,$CLJS.Td($CLJS.Td(a,e),f-1));--e;d=$CLJS.D(d)}}else VB(10,new $CLJS.k(null,5,[MB,0,OB," ",TB,",",SB,3,DB,!0],null),qB(new $CLJS.S(null,1,5,$CLJS.T,[c],null)));return b};
kda=function(a,b){a=yB(b);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);var c=Tz(b);b=c&127;c&=128;var d=$CLJS.M.h(jda,b);0<c&&PB.l($CLJS.G(["Meta-"]));PB.l($CLJS.G([$CLJS.m(d)?d:32>b?["Control-",$CLJS.p.g($CLJS.Eu(b+64))].join(""):$CLJS.F.h(b,127)?"Control-?":$CLJS.Eu(b)]));return a};
lda=function(a,b){var c=yB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);a=bC.g(a);if($CLJS.m($CLJS.F.h?$CLJS.F.h("o",a):$CLJS.F.call(null,"o",a)))tB(!0,"\\o~3,'0o",$CLJS.G([Tz(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h("u",a):$CLJS.F.call(null,"u",a)))tB(!0,"\\u~4,'0x",$CLJS.G([Tz(b)]));else if($CLJS.m($CLJS.F.h?$CLJS.F.h(null,a):$CLJS.F.call(null,null,a)))$CLJS.Qc($CLJS.Rz,$CLJS.m($CLJS.F.h?$CLJS.F.h("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.m($CLJS.F.h?$CLJS.F.h(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.m($CLJS.F.h?$CLJS.F.h("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.m($CLJS.F.h?$CLJS.F.h("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.m($CLJS.F.h?$CLJS.F.h("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.m($CLJS.F.h?$CLJS.F.h("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.m($CLJS.F.h?$CLJS.F.h('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.m($CLJS.F.h?$CLJS.F.h("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.g(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.g(a)].join(""));return c};mda=function(a,b){b=yB(b);a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);PB.l($CLJS.G([a]));return b};eC=function(a){a=$CLJS.C(a);return $CLJS.F.h(cC,a)||$CLJS.F.h(dC,a)};
iC=function(a,b,c){return $CLJS.Zd(Uz(function(d,e){if(eC(e))return new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=HB(fC.g(d),e);var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);var g=Wz(f);f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);f=$CLJS.U.j(f,gC,c);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.R.h(hC.g(d),new $CLJS.S(null,3,5,$CLJS.T,[f,e,g],null))],null)},b,a))};
jC=function(a){a=$CLJS.p.g(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.p.g(a.length-1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.g(c-1)],null):0>c?new $CLJS.S(null,2,5,$CLJS.T,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.h($CLJS.Td(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.h($CLJS.Td(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.h($CLJS.Td(c,0),"0"))for(var e=0;;){if($CLJS.F.h(e,d)||!$CLJS.F.h($CLJS.Td(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.h($CLJS.Td(a,0),"+")?a.substring(1):a;return $CLJS.je(c)?new $CLJS.S(null,2,5,$CLJS.T,["0",0],null):new $CLJS.S(null,2,5,$CLJS.T,[c,parseInt(a,10)-b],null)};
kC=function(a,b,c,d){if($CLJS.m($CLJS.m(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.m(d)?2>d?2:d:0;$CLJS.m(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.h(c,0)?new $CLJS.S(null,4,5,$CLJS.T,[["0",$CLJS.p.g(a)].join(""),b+1,1,e+1],null):new $CLJS.S(null,4,5,$CLJS.T,[a,b,c,e],null);c=$CLJS.J(f,0,null);e=$CLJS.J(f,1,null);d=$CLJS.J(f,2,null);f=$CLJS.J(f,3,null);if($CLJS.m(d)){if(0>d)return new $CLJS.S(null,3,5,$CLJS.T,["0",0,!1],null);if(f>d){b=$CLJS.Td(c,d);a=c.substring(0,d);if(Tz(b)>=Tz("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.R.j($CLJS.p,"1",$CLJS.ng(b+1,"0"));break a}if($CLJS.F.h("9",a.charAt(c)))--c;else{b=$CLJS.R.D($CLJS.p,a.substring(0,c),$CLJS.Eu(Tz(a.charAt(c))+1),$CLJS.ng(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.T;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.S(null,3,5,c,[b,e,a],null)}return new $CLJS.S(null,3,5,$CLJS.T,[a,e,!1],null)}}}return new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null)};
lC=function(a,b,c){var d=0>b?new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(-b-1,"0"))),$CLJS.p.g(a)].join(""),-1],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);a=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);d=$CLJS.E(a);c=$CLJS.m(c)?e+c+1:e+1;c=d<c?[$CLJS.p.g(a),$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.g(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
nda=function(a,b){return 0>b?[".",$CLJS.p.g(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
pC=function(a,b){var c=mC.g(a),d=nC.g(a);b=yB(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var f=0>e?new $CLJS.S(null,2,5,$CLJS.T,["-",-e],null):new $CLJS.S(null,2,5,$CLJS.T,["+",e],null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=jC(f);var l=$CLJS.J(f,0,null),n=$CLJS.J(f,1,null)+$CLJS.Zu.g(a);f=function(){var x=EB.g(a);return $CLJS.m(x)?x:0>e}();var q=$CLJS.Gb(d)&&$CLJS.E(l)-1<=n,u=kC(l,n,d,$CLJS.m(c)?c-($CLJS.m(f)?1:0):null);l=$CLJS.J(u,0,null);n=$CLJS.J(u,1,null);u=$CLJS.J(u,2,null);l=lC(l,$CLJS.m(u)?
n+1:n,d);d=$CLJS.m($CLJS.m(c)?$CLJS.m(d)?1<=d&&$CLJS.F.h(l.charAt(0),"0")&&$CLJS.F.h(l.charAt(1),".")&&l.length>c-($CLJS.m(f)?1:0):d:c)?l.substring(1):l;n=$CLJS.F.h($CLJS.C(d),".");if($CLJS.m(c)){l=d.length;l=$CLJS.m(f)?l+1:l;n=n&&!(l>=c);q=q&&!(l>=c);var v=n||q?l+1:l;$CLJS.m(function(){var x=v>c;return x?oC.g(a):x}())?PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(c,oC.g(a)))])):PB.l($CLJS.G([[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(c-v,OB.g(a)))),$CLJS.p.g($CLJS.m(f)?g:null),n?"0":null,d,q?"0":null].join("")]))}else PB.l($CLJS.G([[$CLJS.p.g($CLJS.m(f)?
g:null),n?"0":null,d,q?"0":null].join("")]));return b};
sC=function(a,b){b=yB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=jC(0>c?-c:c);$CLJS.J(d,0,null);for($CLJS.J(d,1,null);;){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=mC.g(a),l=nC.g(a),n=qC.g(a),q=$CLJS.Zu.g(a),u=function(){var I=rC.g(a);return $CLJS.m(I)?I:"E"}();d=function(){var I=EB.g(a);return $CLJS.m(I)?I:0>c}();var v=0>=q,x=f-(q-1),y=$CLJS.p.g(Math.abs(x));u=[$CLJS.p.g(u),0>x?"-":"+",$CLJS.p.g($CLJS.m(n)?$CLJS.R.h($CLJS.p,$CLJS.ng(n-y.length,"0")):null),y].join("");var B=u.length;
x=$CLJS.E(e);e=[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(-q,"0"))),$CLJS.p.g(e),$CLJS.p.g($CLJS.m(l)?$CLJS.R.h($CLJS.p,$CLJS.ng(l-(x-1)-(0>q?-q:0),"0")):null)].join("");x=$CLJS.m(g)?g-B:null;e=kC(e,0,$CLJS.F.h(q,0)?l-1:0<q?l:0>q?l-1:null,$CLJS.m(x)?x-($CLJS.m(d)?1:0):null);x=$CLJS.J(e,0,null);$CLJS.J(e,1,null);y=$CLJS.J(e,2,null);e=nda(x,q);l=$CLJS.F.h(q,$CLJS.E(x))&&null==l;if($CLJS.Gb(y)){if($CLJS.m(g)){f=e.length+B;f=$CLJS.m(d)?f+1:f;var H=(v=v&&!$CLJS.F.h(f,g))?f+1:f;f=l&&H<g;$CLJS.m(function(){var I=
H>g;I||(I=n,I=$CLJS.m(I)?B-2>n:I);return $CLJS.m(I)?oC.g(a):I}())?PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(g,oC.g(a)))])):PB.l($CLJS.G([[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(g-H-(f?1:0),OB.g(a)))),$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else PB.l($CLJS.G([[$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.S(null,2,5,$CLJS.T,[x,f+1],null)}return b};
oda=function(a,b,c){var d=yB(b),e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);d=jC(0>e?-e:e);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var g=mC.g(a),l=nC.g(a),n=qC.g(a);d=$CLJS.F.h(e,0)?0:d+1;e=$CLJS.m(n)?n+2:4;g=$CLJS.m(g)?g-e:null;$CLJS.m(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=pC(new $CLJS.k(null,6,[mC,g,nC,d,$CLJS.Zu,0,oC,oC.g(a),OB,OB.g(a),EB,EB.g(a)],null),b,c),PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(e," "))])),a):sC(a,b,c)};
pda=function(a,b){b=yB(b);var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=jC(Math.abs(c)),e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null),g=nC.g(a),l=$CLJS.tC.g(a);d=mC.g(a);var n=function(){var u=EB.g(a);return $CLJS.m(u)?u:0>c}(),q=kC(e,f,g,null);e=$CLJS.J(q,0,null);f=$CLJS.J(q,1,null);q=$CLJS.J(q,2,null);g=lC(e,$CLJS.m(q)?f+1:f,g);l=[$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(l-g.indexOf("."),"0"))),g].join("");g=l.length+($CLJS.m(n)?1:0);PB.l($CLJS.G([[$CLJS.m(function(){var u=DB.g(a);return $CLJS.m(u)?n:
u}())?0>c?"-":"+":null,$CLJS.p.g($CLJS.R.h($CLJS.p,$CLJS.ng(d-g,OB.g(a)))),$CLJS.m(function(){var u=$CLJS.Gb(DB.g(a));return u?n:u}())?0>c?"-":"+":null,l].join("")]));return b};qda=function(a,b){var c=uC.g(a);c=$CLJS.m(c)?new $CLJS.S(null,2,5,$CLJS.T,[c,b],null):yB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.vC.g(a);b=0>b||b>=$CLJS.E(d)?$CLJS.C($CLJS.Tu.g(a)):$CLJS.Td(d,b);return $CLJS.m(b)?iC(b,c,gC.g(a)):c};
rda=function(a,b){var c=yB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var d=$CLJS.vC.g(a);b=$CLJS.m(b)?$CLJS.Zd(d):$CLJS.C(d);return $CLJS.m(b)?iC(b,c,gC.g(a)):c};sda=function(a,b){var c=yB(b),d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.vC.g(a);e=$CLJS.m(d)?$CLJS.C(e):null;return $CLJS.m(d)?$CLJS.m(e)?iC(e,b,gC.g(a)):b:c};
tda=function(a,b){var c=wC.g(a),d=$CLJS.C($CLJS.vC.g(a));d=$CLJS.je(d)?zB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=yB(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=0;e=qB(e);for(var g=-1;;){if($CLJS.Gb(c)&&$CLJS.F.h(IA.g(e),g)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");g=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(DB.g(xC.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=iC(b,e,gC.g(a));if($CLJS.F.h(cC,$CLJS.C(g)))return d;
f+=1;var l=IA.g(e);e=g;g=l}};uda=function(a,b){var c=wC.g(a),d=$CLJS.C($CLJS.vC.g(a));d=$CLJS.je(d)?zB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=yB(d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);for(var f=0;;){var g=$CLJS.je(e)&&($CLJS.Gb(DB.g(xC.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=iC(b,qB($CLJS.C(e)),qB($CLJS.D(e)));if($CLJS.F.h(dC,$CLJS.C(g)))return d;f+=1;e=$CLJS.D(e)}};
vda=function(a,b){var c=wC.g(a),d=$CLJS.C($CLJS.vC.g(a)),e=$CLJS.je(d)?zB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;e=$CLJS.J(e,1,null);for(var f=-1;;){if($CLJS.Gb(c)&&$CLJS.F.h(IA.g(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(DB.g(xC.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=iC(b,e,gC.g(a));if($CLJS.F.h(cC,$CLJS.C(f)))return $CLJS.Zd(f);d+=1;var g=IA.g(e);e=f;f=g}};
wda=function(a,b){var c=wC.g(a),d=$CLJS.C($CLJS.vC.g(a)),e=$CLJS.je(d)?zB(b):new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);b=$CLJS.J(e,0,null);d=0;for(e=$CLJS.J(e,1,null);;){var f=$CLJS.je($CLJS.xk.g(e))&&($CLJS.Gb(DB.g(xC.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=$CLJS.xk.g(e);f=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(f),new wB(xB.g(e),$CLJS.D(f),IA.g(e)+1,null,null,null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,e],null);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=iC(b,qB(e),f);
if($CLJS.F.h(dC,$CLJS.C(e)))return f;e=f;d+=1}};yda=function(a,b){if($CLJS.m(DB.g(xC.g(a)))){var c=$CLJS.vC.g(a),d=$CLJS.E(c),e=1<d?$CLJS.Ol.g(fC.g($CLJS.C($CLJS.C(c)))):$CLJS.m(DB.g(a))?"(":null,f=$CLJS.Td(c,1<d?1:0);c=2<d?$CLJS.Ol.g(fC.g($CLJS.C($CLJS.Td(c,2)))):$CLJS.m(DB.g(a))?")":null;d=yB(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var g=eB,l=$A;eB+=1;$A=0;try{UA(e,c),iC(f,qB(b),gC.g(a)),YA()}finally{$A=l,eB=g}}a=d}else a=xda(a,b);return a};
yC=function(a,b,c){for(var d=$CLJS.Lg;;){if($CLJS.je(a))return new $CLJS.S(null,2,5,$CLJS.T,[d,b],null);var e=$CLJS.C(a);a:{var f=new $CLJS.rb,g=$CLJS.Rz;$CLJS.Rz=new $CLJS.gd(f);try{var l=new $CLJS.S(null,2,5,$CLJS.T,[iC(e,b,c),$CLJS.p.g(f)],null);break a}finally{$CLJS.Rz=g}l=void 0}b=$CLJS.J(l,0,null);e=$CLJS.J(l,1,null);if($CLJS.F.h(cC,$CLJS.C(b)))return new $CLJS.S(null,2,5,$CLJS.T,[d,$CLJS.Zd(b)],null);a=$CLJS.D(a);d=$CLJS.kf.h(d,e)}};
xda=function(a,b){var c=function(){var Y=$CLJS.Tu.g(a);return $CLJS.m(Y)?yC(Y,b,gC.g(a)):null}(),d=$CLJS.J(c,0,null);d=$CLJS.J(d,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.m(c)?c:b;c=function(){var Y=zC.g(a);return $CLJS.m(Y)?HB(Y,e):null}();var f=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var g=$CLJS.m(c)?c:e;c=function(){var Y=$CLJS.C(AC.g(f));return $CLJS.m(Y)?Y:0}();var l=function(){var Y=$CLJS.C(BC.g(f));return $CLJS.m(Y)?Y:$CLJS.Yz($CLJS.Rz,$CLJS.Yl)}(),n=$CLJS.vC.g(a);g=yC(n,g,gC.g(a));var q=$CLJS.J(g,
0,null);g=$CLJS.J(g,1,null);var u=function(){var Y=$CLJS.E(q)-1+($CLJS.m(DB.g(a))?1:0)+($CLJS.m(EB.g(a))?1:0);return 1>Y?1:Y}();n=$CLJS.Ve($CLJS.Fm,$CLJS.rg.h($CLJS.E,q));var v=MB.g(a),x=LB.g(a),y=NB.g(a),B=n+u*x;v=B<=v?v:v+y*(1+$CLJS.af(B-v-1,y));var H=v-n;n=function(){var Y=$CLJS.af(H,u);return x>Y?x:Y}();y=H-n*u;n=$CLJS.R.h($CLJS.p,$CLJS.ng(n,OB.g(a)));$CLJS.m($CLJS.m(d)?$CLJS.Yz(bA.g($CLJS.r($CLJS.r($CLJS.Rz))),$CLJS.$z)+c+v>l:d)&&PB.l($CLJS.G([d]));c=y;for(var I=q,Q=function(){var Y=DB.g(a);
return $CLJS.m(Y)?Y:$CLJS.F.h($CLJS.E(I),1)&&$CLJS.Gb(EB.g(a))}();;)if($CLJS.A(I))PB.l($CLJS.G([[$CLJS.p.g($CLJS.Gb(Q)?$CLJS.C(I):null),$CLJS.p.g($CLJS.m(function(){var Y=Q;return $CLJS.m(Y)?Y:(Y=$CLJS.D(I))?Y:EB.g(a)}())?n:null),$CLJS.p.g(0<c?OB.g(a):null)].join("")])),--c,I=d=$CLJS.m(Q)?I:$CLJS.D(I),Q=!1;else break;return g};CC=function(a,b){this.La=a;this.Xi=b;this.C=1074135040;this.K=0};zda=function(a){return new CC(a,$CLJS.P)};DC=function(a,b){this.La=a;this.Yi=b;this.C=1074135040;this.K=0};
Ada=function(a){return new DC(a,$CLJS.P)};
Bda=function(a,b){var c=$CLJS.C(a);return $CLJS.R.h($CLJS.p,$CLJS.C(Vz(function(d){if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.m(e)?e.index+1:e;return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[[d.substring(0,e),$CLJS.Td(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.S(null,2,5,$CLJS.T,[d,null],null)},$CLJS.m($CLJS.m(b)?$CLJS.m(c)?Iz(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
EC=function(a,b,c){this.La=a;this.we=b;this.Zi=c;this.C=1074135040;this.K=0};Cda=function(a){var b=$CLJS.cj.g(!0);return new EC(a,b,$CLJS.P)};FC=function(a,b,c){this.La=a;this.ke=b;this.$i=c;this.C=1074135040;this.K=0};Dda=function(a){var b=$CLJS.cj.g(!1);return new FC(a,b,$CLJS.P)};Eda=function(a,b){var c=$CLJS.m(DB.g(a))?$CLJS.mB:lB;oB(c,$CLJS.tC.g(a));return b};Fda=function(a,b){a=$CLJS.m(DB.g(a))?$CLJS.m(EB.g(a))?gB:iB:$CLJS.m(EB.g(a))?hB:jB;kB(a);return b};
Hda=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null),d=$CLJS.J(a,2,null);a=new RegExp(Gda.source,"g");var e=a.exec(b);return $CLJS.m(e)?(d=$CLJS.C(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.h(",",$CLJS.Td(b,0))?new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b.substring(1),a+1,!0],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[d,c],null),new $CLJS.S(null,3,5,$CLJS.T,[b,a,!1],null)],null)):$CLJS.m(d)?vB("Badly formed parameters in format directive",
c):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[b,c],null)],null)};Ida=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(b.length,0)?null:$CLJS.F.h(b.length,1)&&$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,["V",null,"v",null],null),null),$CLJS.Td(b,0))?FB:$CLJS.F.h(b.length,1)&&$CLJS.F.h("#",$CLJS.Td(b,0))?GB:$CLJS.F.h(b.length,2)&&$CLJS.F.h("'",$CLJS.Td(b,0))?$CLJS.Td(b,1):parseInt(b,10),a],null)};
Kda=function(a,b){return Vz(function(c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null);var f=$CLJS.M.h(Jda,$CLJS.C(d));return $CLJS.m(f)?$CLJS.Ie(c,f)?vB(['Flag "',$CLJS.p.g($CLJS.C(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[d.substring(1),e+1,$CLJS.U.j(c,f,new $CLJS.S(null,2,5,$CLJS.T,[!0,e],null))],
null)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,3,5,$CLJS.T,[d,e,c],null)],null)},new $CLJS.S(null,3,5,$CLJS.T,[a,b,$CLJS.P],null))};
Lda=function(a,b){var c=GC.g(a);$CLJS.m(function(){var d=$CLJS.Gb(EB.g(c));return d?EB.g(b):d}())&&vB(['"@" is an illegal flag for format directive "',$CLJS.p.g(HC.g(a)),'"'].join(""),$CLJS.Td(EB.g(b),1));$CLJS.m(function(){var d=$CLJS.Gb(DB.g(c));return d?DB.g(b):d}())&&vB(['":" is an illegal flag for format directive "',$CLJS.p.g(HC.g(a)),'"'].join(""),$CLJS.Td(DB.g(b),1));$CLJS.m(function(){var d=$CLJS.Gb(IC.g(c));return d?(d=EB.g(b),$CLJS.m(d)?DB.g(b):d):d}())&&vB(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.g(HC.g(a)),'"'].join(""),function(){var d=$CLJS.Td(DB.g(b),1),e=$CLJS.Td(EB.g(b),1);return d<e?d:e}())};
Nda=function(a,b,c,d){Lda(a,c);$CLJS.E(b)>$CLJS.E(fC.g(a))&&vB(tB(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([HC.g(a),$CLJS.E(b),$CLJS.E(fC.g(a))])),$CLJS.Zd($CLJS.C(b)));$CLJS.Ci($CLJS.rg.j(function(e,f){var g=$CLJS.C(e);return null==g||$CLJS.Ie(Mda,g)||$CLJS.F.h($CLJS.Zd($CLJS.Zd(f)),$CLJS.Ob(g))?null:vB(["Parameter ",$CLJS.ui($CLJS.C(f)),' has bad type in directive "',$CLJS.p.g(HC.g(a)),'": ',$CLJS.p.g($CLJS.Ob(g))].join(""),
$CLJS.Zd(e))},b,fC.g(a)));return $CLJS.fn.l($CLJS.G([$CLJS.oh.h($CLJS.P,$CLJS.lf(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.J(x,0,null);u.add(new $CLJS.S(null,2,5,$CLJS.T,[y,new $CLJS.S(null,2,5,$CLJS.T,[x,d],null)],null));v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):
$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);n=$CLJS.J(n,0,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,new $CLJS.S(null,2,5,$CLJS.T,[n,d],null)],null),g($CLJS.zd(l)))}return null}},null,null)}(fC.g(a))}())),$CLJS.Sb(function(e,f){return $CLJS.R.j($CLJS.U,e,f)},$CLJS.P,$CLJS.ug(function(e){return $CLJS.C($CLJS.Td(e,1))},$CLJS.Pz($CLJS.gi(fC.g(a)),b))),c]))};
Pda=function(a,b){b=Vz(Hda,new $CLJS.S(null,3,5,$CLJS.T,[a,b,!1],null));a=$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);b=Kda(b,c);$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);c=$CLJS.C(d);var f=$CLJS.M.h(Oda,c.toUpperCase()),g=$CLJS.m(f)?Nda(f,$CLJS.rg.h(Ida,a),b,e):null;$CLJS.Gb(c)&&vB("Format string ended in the middle of a directive",e);$CLJS.Gb(f)&&vB(['Directive "',$CLJS.p.g(c),'" is undefined'].join(""),
e);return new $CLJS.S(null,2,5,$CLJS.T,[new CB(function(){var l=JC.g(f);return l.h?l.h(g,e):l.call(null,g,e)}(),f,g,e,null,null,null),function(){var l=d.substring(1),n=e+1;if($CLJS.F.h("\n",HC.g(f))&&$CLJS.Gb(DB.g(g)))a:{var q=new $CLJS.S(null,2,5,$CLJS.T,[" ","\t"],null);q=$CLJS.ke(q)?$CLJS.si(q):$CLJS.pi([q]);for(var u=0;;){var v;(v=$CLJS.F.h(u,$CLJS.E(l)))||(v=$CLJS.Td(l,u),v=q.g?q.g(v):q.call(null,v),v=$CLJS.Gb(v));if(v){q=u;break a}u+=1}}else q=0;return new $CLJS.S(null,2,5,$CLJS.T,[l.substring(q),
n+q],null)}()],null)};KC=function(a,b){return new CB(function(c,d){PB.l($CLJS.G([a]));return d},null,new $CLJS.k(null,1,[$CLJS.Ol,a],null),b,null,null,null)};NC=function(a,b){var c=Qda(LC.g(MC.g(a)),$CLJS.Vu.g(a),b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[new CB(hC.g(a),MC.g(a),$CLJS.fn.l($CLJS.G([fC.g(a),Zca(b,$CLJS.Vu.g(a))])),$CLJS.Vu.g(a),null,null,null),c],null)};
Rda=function(a,b,c){return Vz(function(d){if($CLJS.je(d))return vB("No closing bracket found.",b);var e=$CLJS.C(d);d=$CLJS.D(d);if($CLJS.m($CLJS.OC.g(LC.g(MC.g(e)))))e=NC(e,d);else if($CLJS.F.h($CLJS.OC.g(a),HC.g(MC.g(e))))e=new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[PC,fC.g(e),null,d],null)],null);else{var f=$CLJS.Xu.g(LC.g(MC.g(e)));f=$CLJS.m(f)?DB.g(fC.g(e)):f;e=$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Tu,null,fC.g(e),d],null)],null):
$CLJS.m($CLJS.Xu.g(LC.g(MC.g(e))))?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Xu,null,null,d],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[e,d],null)}return e},c)};
Qda=function(a,b,c){return $CLJS.Zd(Vz(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);d=$CLJS.J(d,2,null);var g=Rda(a,b,d);d=$CLJS.J(g,0,null);var l=$CLJS.J(g,1,null);g=$CLJS.J(l,0,null);var n=$CLJS.J(l,1,null),q=$CLJS.J(l,2,null);l=$CLJS.J(l,3,null);return $CLJS.F.h(g,PC)?new $CLJS.S(null,2,5,$CLJS.T,[null,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QC.l($CLJS.qg,$CLJS.G([e,$CLJS.Pf([$CLJS.m(f)?$CLJS.Tu:$CLJS.vC,new $CLJS.S(null,1,5,$CLJS.T,[d],null),xC,n])])),l],null)],null):$CLJS.F.h(g,$CLJS.Tu)?
$CLJS.m($CLJS.Tu.g(e))?vB('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Gb($CLJS.Tu.g(a))?vB('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.h(RC,$CLJS.Tu.g(a))&&$CLJS.A($CLJS.vC.g(e))?vB('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.h(RC,$CLJS.Tu.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,2,[$CLJS.Tu,new $CLJS.S(null,1,5,
$CLJS.T,[d],null),zC,q],null)])),!1,l],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.vC,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!0,l],null)],null):$CLJS.F.h(g,$CLJS.Xu)?$CLJS.m(f)?vB('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Gb(SC.g(a))?vB('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.S(null,2,5,$CLJS.T,[!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.QC.l($CLJS.qg,$CLJS.G([e,new $CLJS.k(null,1,[$CLJS.vC,new $CLJS.S(null,1,5,$CLJS.T,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.vC,$CLJS.Lg],null),!1,c],null)))};Sda=function(a){return $CLJS.C(Vz(function(b){var c=$CLJS.C(b);b=$CLJS.D(b);var d=LC.g(MC.g(c));return $CLJS.m($CLJS.OC.g(d))?NC(c,b):new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)},a))};
pB=function(a){var b=uB;uB=a;try{return Sda($CLJS.C(Vz(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.je(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.S(null,2,5,$CLJS.T,[KC(d,c),new $CLJS.S(null,2,5,$CLJS.T,["",c+d.length],null)],null):0===e?Pda(d.substring(1),c+1):new $CLJS.S(null,2,5,$CLJS.T,[KC(d.substring(0,e),c),new $CLJS.S(null,2,5,$CLJS.T,[d.substring(e),e+c],null)],null)},new $CLJS.S(null,2,5,$CLJS.T,[a,0],null))))}finally{uB=
b}};sB=function(a,b){Uz(function(c,d){if(eC(d))return new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=HB(fC.g(c),d);var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=Wz(e);e=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.U.j(e,gC,d);return new $CLJS.S(null,2,5,$CLJS.T,[null,$CLJS.R.h(hC.g(c),new $CLJS.S(null,3,5,$CLJS.T,[e,d,f],null))],null)},b,a);return null};
UC=function(a){var b=$CLJS.C(a);b=TC.g?TC.g(b):TC.call(null,b);return $CLJS.m($CLJS.m(b)?$CLJS.F.h(2,$CLJS.E(a)):b)?($CLJS.Qc($CLJS.Rz,b),$CLJS.cB($CLJS.Zd(a)),!0):null};
VC=function(a){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("[","]");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.cB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Rz," ");kB(jB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Rz,"...");break}YA()}finally{$A=c,eB=b}}return null};
WC=function(a){var b=$CLJS.pe(a)?null:function(){var n=new $CLJS.ud(function(){return $CLJS.Wi},Tda,$CLJS.Sh([$CLJS.om,$CLJS.Dk,$CLJS.V,$CLJS.em,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.um,$CLJS.Zj,$CLJS.W,$CLJS.dm],[!0,$CLJS.Mj,Uda,"cljs/core.cljs",15,1,10543,10543,$CLJS.X(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Mk],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.m($CLJS.Wi)?$CLJS.Wi.J:null]));return n.g?n.g(a):n.call(null,a)}(),c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.m(b)?
b:a,e=$CLJS.m(c)?["#:",$CLJS.p.g(c),"{"].join(""):"{";if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{c=eB;b=$A;eB+=1;$A=0;try{UA(e,"}");e=0;for(var f=$CLJS.A(d);;){if($CLJS.Gb($CLJS.yb)||e<$CLJS.yb){if(f){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{d=eB;var g=$A;eB+=1;$A=0;try{UA(null,null),$CLJS.cB($CLJS.C($CLJS.C(f))),$CLJS.Qc($CLJS.Rz," "),kB(jB),$A=0,$CLJS.cB($CLJS.C($CLJS.D($CLJS.C(f)))),YA()}finally{$A=g,eB=d}}if($CLJS.D(f)){$CLJS.Qc($CLJS.Rz,", ");kB(jB);d=e+1;var l=$CLJS.D(f);e=d;f=l;continue}}}else $CLJS.Qc($CLJS.Rz,
"...");break}YA()}finally{$A=b,eB=c}}return null};XC=function(a){return $CLJS.Qc($CLJS.Rz,$CLJS.aj.l($CLJS.G([a])))};$C=function(a){return a instanceof $CLJS.ah?YC:(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Jz,a)):$CLJS.Nb($CLJS.Jz,a))?ZC:a instanceof $CLJS.w?$CLJS.nk:$CLJS.we(a)?$CLJS.pl:$CLJS.oe(a)?$CLJS.Xl:$CLJS.qe(a)?$CLJS.zl:$CLJS.le(a)?$CLJS.Hk:null==a?null:$CLJS.Pj};
aD=function(a){return $CLJS.qe(a)?new $CLJS.S(null,2,5,$CLJS.T,["[","]"],null):new $CLJS.S(null,2,5,$CLJS.T,["(",")"],null)};
Vda=function(a){if($CLJS.ne(a)){var b=aD(a),c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null),e=$CLJS.A(a),f=$CLJS.C(e),g=$CLJS.D(e);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var l=eB,n=$A,q=eB+1;eB=q;$A=0;try{UA(c,d);(function(){var Ea=bD("~w~:i");return function(){function jb(Fb){var Mb=null;if(0<arguments.length){Mb=0;for(var ac=Array(arguments.length-0);Mb<ac.length;)ac[Mb]=arguments[Mb+0],++Mb;Mb=new $CLJS.z(ac,0,null)}return lb.call(this,Mb)}function lb(Fb){Fb=qB(Fb);return sB(Ea,Fb)}jb.v=0;jb.B=function(Fb){Fb=
$CLJS.A(Fb);return lb(Fb)};jb.l=lb;return jb}()})()(f);for(var u=g;;)if($CLJS.A(u)){(function(){var Ea=bD(" ");return function(jb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=qB(Tb);return sB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u," ",Ea,l,n,q,0,b,c,d,a,e,f,g,f,g)})()();
var v=$CLJS.C(u);if($CLJS.ne(v)){var x=aD(v),y=$CLJS.J(x,0,null),B=$CLJS.J(x,1,null);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var H=eB,I=$A,Q=eB+1;eB=Q;$A=0;try{UA(y,B);if($CLJS.F.h($CLJS.E(v),3)&&$CLJS.Zd(v)instanceof $CLJS.N){var Y=v,aa=$CLJS.J(Y,0,null),ha=$CLJS.J(Y,1,null),qa=$CLJS.J(Y,2,null);(function(){var Ea=bD("~w ~w ");return function(jb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+
0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=qB(Tb);return sB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~w ~w ",Ea,Y,aa,ha,qa,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)})()(aa,ha);$CLJS.ne(qa)?function(){var Ea=$CLJS.qe(qa)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",jb="string"===typeof Ea?bD(Ea):Ea;return function(lb,Fb,Mb){return function(){function ac(Ub){var ge=null;if(0<arguments.length){ge=0;
for(var aA=Array(arguments.length-0);ge<aA.length;)aA[ge]=arguments[ge+0],++ge;ge=new $CLJS.z(aA,0,null)}return Tb.call(this,ge)}function Tb(Ub){Ub=qB(Ub);return sB(Mb,Ub)}ac.v=0;ac.B=function(Ub){Ub=$CLJS.A(Ub);return Tb(Ub)};ac.l=Tb;return ac}()}(u,Ea,jb,Y,aa,ha,qa,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()(qa):$CLJS.cB(qa)}else $CLJS.R.h(function(){var Ea=bD("~w ~:i~@{~w~^ ~:_~}");return function(jb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=
Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=qB(Tb);return sB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Ea,H,I,Q,0,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}(),v);YA()}finally{$A=I,eB=H}}$CLJS.D(u)&&function(){var Ea=bD("~_");return function(jb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-
0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=qB(Tb);return sB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~_",Ea,x,y,B,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()()}else $CLJS.cB(v),$CLJS.D(u)&&function(){var Ea=bD("~:_");return function(jb,lb,Fb){return function(){function Mb(Tb){var Ub=null;if(0<arguments.length){Ub=0;for(var ge=Array(arguments.length-0);Ub<ge.length;)ge[Ub]=arguments[Ub+0],++Ub;
Ub=new $CLJS.z(ge,0,null)}return ac.call(this,Ub)}function ac(Tb){Tb=qB(Tb);return sB(Fb,Tb)}Mb.v=0;Mb.B=function(Tb){Tb=$CLJS.A(Tb);return ac(Tb)};Mb.l=ac;return Mb}()}(u,"~:_",Ea,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()();u=$CLJS.D(u)}else break;YA()}finally{$A=n,eB=l}}}else $CLJS.cB(a)};
Wda=function(a,b){$CLJS.A(a)&&($CLJS.m(b)?function(){var c=bD(" ~_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=qB(f);return sB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()():function(){var c=bD(" ~@_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<
l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=qB(f);return sB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;return d}()}()(),function(){var c=bD("~{~w~^ ~_~}");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.z(l,0,null)}return e.call(this,g)}function e(f){f=qB(f);return sB(c,f)}d.v=0;d.B=function(f){f=$CLJS.A(f);return e(f)};d.l=e;
return d}()}()(a))};Xda=function(a){$CLJS.A(a)&&function(){var b=bD(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var g=Array(arguments.length-0);f<g.length;)g[f]=arguments[f+0],++f;f=new $CLJS.z(g,0,null)}return d.call(this,f)}function d(e){e=qB(e);return sB(b,e)}c.v=0;c.B=function(e){e=$CLJS.A(e);return d(e)};c.l=d;return c}()}()(a)};
dD=function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d="string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var f=
eB,g=$A;eB+=1;$A=0;try{UA("(",")"),function(){var l=bD("~w ~1I~@_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=qB(u);return sB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(a,b),$CLJS.m(c)&&function(){var l=bD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=qB(u);return sB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}()(c),$CLJS.m(d)&&function(){var l=bD(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=qB(u);return sB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};
n.l=q;return n}()}()(d),$CLJS.qe($CLJS.C(e))?Wda(e,$CLJS.m(c)?c:d):Xda(e),YA()}finally{$A=g,eB=f}}return null}return cD(a)};
Yda=function(a){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("[","]");for(var d=0;;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if($CLJS.A(a)){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var e=eB,f=$A;eB+=1;$A=0;try{UA(null,null),$CLJS.cB($CLJS.C(a)),$CLJS.D(a)&&($CLJS.Qc($CLJS.Rz," "),kB(hB),$CLJS.cB($CLJS.Zd(a))),YA()}finally{$A=f,eB=e}}if($CLJS.D($CLJS.zd(a))){$CLJS.Qc($CLJS.Rz," ");kB(jB);e=d+1;var g=$CLJS.D($CLJS.zd(a));d=e;a=g;continue}}}else $CLJS.Qc($CLJS.Rz,"...");
break}YA()}finally{$A=c,eB=b}}};
eD=function(a){var b=$CLJS.C(a);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var c=eB,d=$A;eB+=1;$A=0;try{UA("(",")"),$CLJS.D(a)&&$CLJS.qe($CLJS.Zd(a))?(function(){var e=bD("~w ~1I~@_");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.z(q,0,null)}return g.call(this,n)}function g(l){l=qB(l);return sB(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()(b),Yda($CLJS.Zd(a)),function(){var e=
bD(" ~_~{~w~^ ~_~}");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.z(q,0,null)}return g.call(this,n)}function g(l){l=qB(l);return sB(e,l)}f.v=0;f.B=function(l){l=$CLJS.A(l);return g(l)};f.l=g;return f}()}()($CLJS.D($CLJS.zd(a)))):cD(a),YA()}finally{$A=d,eB=c}}return null};
cD=function(a){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("(",")");oB(lB,1);for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.cB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Rz," ");kB(jB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Rz,"...");break}YA()}finally{$A=c,eB=b}}return null};$CLJS.Rz=null;
$CLJS.QC=function QC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QC.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
$CLJS.QC.l=function(a,b){if($CLJS.m($CLJS.Xf($CLJS.Ye,b))){var c=function(d,e){var f=$CLJS.tc(e),g=$CLJS.uc(e);if($CLJS.Ie(d,f)){e=$CLJS.U.j;var l=$CLJS.M.h(d,f);g=a.h?a.h(l,g):a.call(null,l,g);d=e.call($CLJS.U,d,f,g)}else d=$CLJS.U.j(d,f,g);return d};return $CLJS.Ve(function(d,e){return $CLJS.Sb(c,$CLJS.m(d)?d:$CLJS.P,$CLJS.A(e))},b)}return null};$CLJS.QC.v=1;$CLJS.QC.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var fD=function fD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return fD.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};fD.l=function(a){a=$CLJS.A(a);for(var b=$CLJS.Tc($CLJS.qh);;)if(a){var c=$CLJS.D(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.g($CLJS.C(a))].join(""));var d=$CLJS.D(c);b=$CLJS.rh.j(b,$CLJS.C(a),$CLJS.C(c));a=d}else return $CLJS.Vc(b)};fD.v=0;fD.B=function(a){return this.l($CLJS.A(a))};
$CLJS.UB=function UB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return UB.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};$CLJS.UB.l=function(a){return $CLJS.Ti(a,$CLJS.U.j($CLJS.zb(),$CLJS.tb,!1))};$CLJS.UB.v=0;$CLJS.UB.B=function(a){return this.l($CLJS.A(a))};mA=new $CLJS.N(null,"trailing-white-space","trailing-white-space",1496006996);gD=new $CLJS.N(null,"relative-to","relative-to",-470100051);AA=new $CLJS.N(null,"intra-block-nl","intra-block-nl",1808826875);
rC=new $CLJS.N(null,"exponentchar","exponentchar",1986664222);Zda=new $CLJS.w(null,"when-let","when-let",-1383043480,null);hB=new $CLJS.N(null,"miser","miser",-556060186);$CLJS.mB=new $CLJS.N(null,"current","current",-1088038603);$da=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);aea=new $CLJS.w(null,"..","..",-300507420,null);pA=new $CLJS.N(null,"end-pos","end-pos",-1643883926);yA=new $CLJS.N(null,"per-line-prefix","per-line-prefix",846941813);YC=new $CLJS.N(null,"queue","queue",1455835879);
bea=new $CLJS.w(null,"if-let","if-let",1803593690,null);WA=new $CLJS.N(null,"end","end",-268185958);GB=new $CLJS.N(null,"remaining-arg-count","remaining-arg-count",-1216589335);GA=new $CLJS.N(null,"logical-blocks","logical-blocks",-1466339776);cea=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);dea=new $CLJS.w(null,"when-first","when-first",821699168,null);TA=new $CLJS.N(null,"start-block-t","start-block-t",-373430594);eea=new $CLJS.w(null,"binding","binding",-2114503176,null);
LC=new $CLJS.N(null,"bracket-info","bracket-info",-1600092774);KA=new $CLJS.N(null,"writing","writing",-1486865108);FB=new $CLJS.N(null,"parameter-from-args","parameter-from-args",-758446196);RA=new $CLJS.N(null,"logical-block-callback","logical-block-callback",1612691194);uC=new $CLJS.N(null,"selector","selector",762528866);fea=new $CLJS.w(null,"struct","struct",325972931,null);zA=new $CLJS.N(null,"indent","indent",-148200125);gea=new $CLJS.w(null,"loop","loop",1244978678,null);
BC=new $CLJS.N(null,"max-columns","max-columns",1742323262);IC=new $CLJS.N(null,"both","both",-393648840);hD=new $CLJS.N(null,"colnum","colnum",2023796854);hea=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.iD=new $CLJS.N(null,"length","length",588987862);bC=new $CLJS.N(null,"char-format","char-format",-1016499218);jD=new $CLJS.N(null,"prefix","prefix",-265908465);dC=new $CLJS.N(null,"colon-up-arrow","colon-up-arrow",244853007);VA=new $CLJS.N(null,"suffix","suffix",367373057);
EB=new $CLJS.N(null,"at","at",1476951349);lA=new $CLJS.N(null,"nl-t","nl-t",-1608382114);HC=new $CLJS.N(null,"directive","directive",793559132);fda=new $CLJS.N(null,"buffer-level","buffer-level",928864731);gB=new $CLJS.N(null,"mandatory","mandatory",542802336);PA=new $CLJS.N(null,"pretty-writer","pretty-writer",-1222834267);sA=new $CLJS.N(null,"done-nl","done-nl",-381024340);iea=new $CLJS.w(null,"condp","condp",1054325175,null);xB=new $CLJS.N(null,"seq","seq",-1817803783);
jea=new $CLJS.w(null,"defn","defn",-126010802,null);NB=new $CLJS.N(null,"colinc","colinc",-584873385);cC=new $CLJS.N(null,"up-arrow","up-arrow",1705310333);PC=new $CLJS.N(null,"right-bracket","right-bracket",951856080);kea=new $CLJS.N(null,"radix","radix",857016463);RC=new $CLJS.N(null,"first","first",-644103046);eda=new $CLJS.N(null,"sections","sections",-886710106);$CLJS.SA=new $CLJS.N(null,"start","start",-355208981);lea=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
mea=new $CLJS.w(null,"defn-","defn-",1097765044,null);nea=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);lB=new $CLJS.N(null,"block","block",664686210);oea=new $CLJS.w(null,"when","when",1064114221,null);zC=new $CLJS.N(null,"else-params","else-params",-832171646);$CLJS.kD=new $CLJS.N(null,"count","count",2139924085);$CLJS.OC=new $CLJS.N(null,"right","right",-452581833);jB=new $CLJS.N(null,"linear","linear",872268697);pea=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
OB=new $CLJS.N(null,"padchar","padchar",2018584530);MB=new $CLJS.N(null,"mincol","mincol",1230695445);qea=new $CLJS.N(null,"not-delivered","not-delivered",1599158697);uA=new $CLJS.N(null,"miser-width","miser-width",-1310049437);rea=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);Uda=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);SC=new $CLJS.N(null,"allows-separator","allows-separator",-818967742);HA=new $CLJS.N(null,"buffering","buffering",-876713613);
lD=new $CLJS.N(null,"arg1","arg1",951899358);gC=new $CLJS.N(null,"base-args","base-args",-1268706822);mD=new $CLJS.N(null,"arg3","arg3",-1486822496);nD=new $CLJS.N(null,"arg2","arg2",1729550917);SB=new $CLJS.N(null,"commainterval","commainterval",-1980061083);sea=new $CLJS.N(null,"right-margin","right-margin",-810413306);JA=new $CLJS.N(null,"buffer-blob","buffer-blob",-1830112173);tea=new $CLJS.w(null,"with-open","with-open",172119667,null);
Tda=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);uea=new $CLJS.N(null,"lines","lines",-700165781);nB=new $CLJS.N(null,"indent-t","indent-t",528318969);xC=new $CLJS.N(null,"right-params","right-params",-1790676237);DB=new $CLJS.N(null,"colon","colon",-965200945);vea=new $CLJS.w(null,"if-not","if-not",-265415609,null);hC=new $CLJS.N(null,"func","func",-238706040);$CLJS.oD=new $CLJS.N(null,"last","last",1105735132);ZC=new $CLJS.N(null,"deref","deref",-145586795);
wea=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);wC=new $CLJS.N(null,"max-iterations","max-iterations",2021275563);xea=new $CLJS.w(null,"cond","cond",1606708055,null);LB=new $CLJS.N(null,"minpad","minpad",323570901);xA=new $CLJS.N(null,"logical-block","logical-block",-581022564);yea=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);XA=new $CLJS.N(null,"end-block-t","end-block-t",1544648735);pD=new $CLJS.N(null,"stream","stream",1534941648);
fC=new $CLJS.N(null,"params","params",710516235);zea=new $CLJS.N(null,"circle","circle",1903212362);qA=new $CLJS.N(null,"start-pos","start-pos",668789086);GC=new $CLJS.N(null,"flags","flags",1775418075);iB=new $CLJS.N(null,"fill","fill",883462889);dda=new $CLJS.N(null,"buffer-block","buffer-block",-10937307);JC=new $CLJS.N(null,"generator-fn","generator-fn",811851656);vA=new $CLJS.N(null,"start-col","start-col",668080143);mC=new $CLJS.N(null,"w","w",354169001);$CLJS.tC=new $CLJS.N(null,"n","n",562130025);
MC=new $CLJS.N(null,"def","def",-1043430536);qC=new $CLJS.N(null,"e","e",1381269198);nC=new $CLJS.N(null,"d","d",1972142424);oC=new $CLJS.N(null,"overflowchar","overflowchar",-1620088106);AC=new $CLJS.N(null,"min-remaining","min-remaining",962687677);TB=new $CLJS.N(null,"commachar","commachar",652859327);qD=new $CLJS.N(null,"section","section",-300141526);rD=new $CLJS.N(null,"pretty","pretty",-1916372486);Aea=new $CLJS.w(null,"let","let",358118826,null);
$CLJS.vC=new $CLJS.N(null,"clauses","clauses",1454841241);Bea=new $CLJS.w(null,"defonce","defonce",-1681484013,null);bA=new $CLJS.N(null,"base","base",185279322);kA=new $CLJS.N(null,"type-tag","type-tag",-1873863267);Cea=new $CLJS.w(null,"locking","locking",1542862874,null);IA=new $CLJS.N(null,"pos","pos",-864607220);Dea=new $CLJS.N(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.$z=new $CLJS.N(null,"cur","cur",1153190599);var PB=function PB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return PB.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)},bB,OA,tD,uD,vD,JB,IB,eB,$A,wD;PB.l=function(a){return $CLJS.Qc($CLJS.Rz,$CLJS.R.h($CLJS.UB,a))};PB.v=0;PB.B=function(a){return this.l($CLJS.A(a))};var Qz=function Qz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Qz.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
Qz.l=function(a){return $CLJS.Qc($CLJS.Rz,$CLJS.R.h($CLJS.aj,a))};Qz.v=0;Qz.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=cA.prototype;$CLJS.h.O=function(a,b){return new cA(this.La,this.Bg,this.Hd,b)};$CLJS.h.N=function(){return this.Vi};$CLJS.h.$b=function(){return this.Hd};$CLJS.h.Sc=function(){return Kz(this.La)};
$CLJS.h.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Zz(this,$CLJS.$z,$CLJS.Yz(this,$CLJS.$z)+$CLJS.E(b)):(Zz(this,$CLJS.$z,$CLJS.E(b)-a-1),Zz(this,$CLJS.Xk,$CLJS.Yz(this,$CLJS.Xk)+$CLJS.E($CLJS.ug(function(c){return $CLJS.F.h(c,"\n")},b)))),$CLJS.Qc($CLJS.Yz(this,bA),b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $ca(this,b);throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));
};$CLJS.h=dA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "parent":return this.parent;case "section":return this.Mb;case "start-col":return this.Eb;case "indent":return this.Bb;case "done-nl":return this.Gb;case "intra-block-nl":return this.Hb;case "prefix":return this.prefix;case "per-line-prefix":return this.Lb;case "suffix":return this.Nb;case "logical-block-callback":return this.Ib;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.av,this.parent],null),new $CLJS.S(null,2,5,$CLJS.T,[qD,this.Mb],null),new $CLJS.S(null,2,5,$CLJS.T,[vA,this.Eb],null),new $CLJS.S(null,2,5,$CLJS.T,[zA,this.Bb],null),new $CLJS.S(null,2,5,$CLJS.T,[sA,this.Gb],null),new $CLJS.S(null,2,5,$CLJS.T,[AA,this.Hb],null),new $CLJS.S(null,2,5,
$CLJS.T,[jD,this.prefix],null),new $CLJS.S(null,2,5,$CLJS.T,[yA,this.Lb],null),new $CLJS.S(null,2,5,$CLJS.T,[VA,this.Nb],null),new $CLJS.S(null,2,5,$CLJS.T,[RA,this.Ib],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,10,new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.av,qD,vA,zA,sA,AA,jD,yA,VA,RA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 10+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1977012399^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.parent,b.parent)&&$CLJS.F.h(this.Mb,b.Mb)&&$CLJS.F.h(this.Eb,b.Eb)&&$CLJS.F.h(this.Bb,b.Bb)&&$CLJS.F.h(this.Gb,b.Gb)&&$CLJS.F.h(this.Hb,b.Hb)&&$CLJS.F.h(this.prefix,b.prefix)&&$CLJS.F.h(this.Lb,b.Lb)&&$CLJS.F.h(this.Nb,b.Nb)&&$CLJS.F.h(this.Ib,b.Ib)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,10,[VA,null,zA,null,$CLJS.av,null,qD,null,sA,null,vA,null,jD,null,yA,null,RA,null,AA,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.av,b):$CLJS.O.call(null,$CLJS.av,b))?new dA(c,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qD,b):$CLJS.O.call(null,qD,b))?new dA(this.parent,c,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(vA,b):$CLJS.O.call(null,vA,b))?new dA(this.parent,this.Mb,c,this.Bb,this.Gb,this.Hb,this.prefix,
this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(zA,b):$CLJS.O.call(null,zA,b))?new dA(this.parent,this.Mb,this.Eb,c,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(sA,b):$CLJS.O.call(null,sA,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,c,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(AA,b):$CLJS.O.call(null,AA,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,c,this.prefix,
this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(jD,b):$CLJS.O.call(null,jD,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,c,this.Lb,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yA,b):$CLJS.O.call(null,yA,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,c,this.Nb,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(VA,b):$CLJS.O.call(null,VA,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,
this.Lb,c,this.Ib,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(RA,b):$CLJS.O.call(null,RA,b))?new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,c,this.F,this.m,null):new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.Je($CLJS.av,this.parent),new $CLJS.Je(qD,this.Mb),new $CLJS.Je(vA,this.Eb),new $CLJS.Je(zA,this.Bb),new $CLJS.Je(sA,this.Gb),new $CLJS.Je(AA,this.Hb),new $CLJS.Je(jD,this.prefix),new $CLJS.Je(yA,this.Lb),new $CLJS.Je(VA,this.Nb),new $CLJS.Je(RA,this.Ib)],null),this.m))};$CLJS.h.O=function(a,b){return new dA(this.parent,this.Mb,this.Eb,this.Bb,this.Gb,this.Hb,this.prefix,this.Lb,this.Nb,this.Ib,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=fA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "data":return this.data;case "trailing-white-space":return this.wc;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};
$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[kA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bl,this.data],null),new $CLJS.S(null,2,5,$CLJS.T,[mA,this.wc],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[pA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[kA,$CLJS.bl,mA,qA,pA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1809113693^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.data,b.data)&&$CLJS.F.h(this.wc,b.wc)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,5,[pA,null,mA,null,kA,null,qA,null,$CLJS.bl,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new fA(this.ja,this.data,this.wc,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(kA,b):$CLJS.O.call(null,kA,b))?new fA(c,this.data,this.wc,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.bl,b):$CLJS.O.call(null,$CLJS.bl,b))?new fA(this.ja,c,this.wc,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(mA,b):$CLJS.O.call(null,mA,b))?new fA(this.ja,this.data,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new fA(this.ja,this.data,this.wc,
c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(pA,b):$CLJS.O.call(null,pA,b))?new fA(this.ja,this.data,this.wc,this.ia,c,this.F,this.m,null):new fA(this.ja,this.data,this.wc,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Je(kA,this.ja),new $CLJS.Je($CLJS.bl,this.data),new $CLJS.Je(mA,this.wc),new $CLJS.Je(qA,this.ia),new $CLJS.Je(pA,this.ha)],null),this.m))};
$CLJS.h.O=function(a,b){return new fA(this.ja,this.data,this.wc,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=gA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "type":return this.type;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[kA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk,this.type],null),new $CLJS.S(null,2,5,$CLJS.T,[xA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[pA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,5,new $CLJS.S(null,5,5,$CLJS.T,[kA,$CLJS.Wk,xA,qA,pA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 5+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1640656800^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.type,b.type)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,5,[pA,null,$CLJS.Wk,null,xA,null,kA,null,qA,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new gA(this.ja,this.type,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(kA,b):$CLJS.O.call(null,kA,b))?new gA(c,this.type,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Wk,b):$CLJS.O.call(null,$CLJS.Wk,b))?new gA(this.ja,c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(xA,b):$CLJS.O.call(null,xA,b))?new gA(this.ja,this.type,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new gA(this.ja,this.type,this.la,
c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(pA,b):$CLJS.O.call(null,pA,b))?new gA(this.ja,this.type,this.la,this.ia,c,this.F,this.m,null):new gA(this.ja,this.type,this.la,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,5,5,$CLJS.T,[new $CLJS.Je(kA,this.ja),new $CLJS.Je($CLJS.Wk,this.type),new $CLJS.Je(xA,this.la),new $CLJS.Je(qA,this.ia),new $CLJS.Je(pA,this.ha)],null),this.m))};
$CLJS.h.O=function(a,b){return new gA(this.ja,this.type,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=hA.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[kA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[xA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[pA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[kA,xA,qA,pA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-414877272^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[pA,null,xA,null,kA,null,qA,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new hA(this.ja,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(kA,b):$CLJS.O.call(null,kA,b))?new hA(c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(xA,b):$CLJS.O.call(null,xA,b))?new hA(this.ja,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new hA(this.ja,this.la,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(pA,b):$CLJS.O.call(null,pA,b))?new hA(this.ja,this.la,this.ia,c,this.F,this.m,null):new hA(this.ja,this.la,
this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(kA,this.ja),new $CLJS.Je(xA,this.la),new $CLJS.Je(qA,this.ia),new $CLJS.Je(pA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new hA(this.ja,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=iA.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[kA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[xA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[pA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[kA,xA,qA,pA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1365867980^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[pA,null,xA,null,kA,null,qA,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new iA(this.ja,this.la,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(kA,b):$CLJS.O.call(null,kA,b))?new iA(c,this.la,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(xA,b):$CLJS.O.call(null,xA,b))?new iA(this.ja,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new iA(this.ja,this.la,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(pA,b):$CLJS.O.call(null,pA,b))?new iA(this.ja,this.la,this.ia,c,this.F,this.m,null):new iA(this.ja,this.la,
this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(kA,this.ja),new $CLJS.Je(xA,this.la),new $CLJS.Je(qA,this.ia),new $CLJS.Je(pA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new iA(this.ja,this.la,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=jA.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":return this.ja;case "logical-block":return this.la;case "relative-to":return this.lc;case "offset":return this.offset;case "start-pos":return this.ia;case "end-pos":return this.ha;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[kA,this.ja],null),new $CLJS.S(null,2,5,$CLJS.T,[xA,this.la],null),new $CLJS.S(null,2,5,$CLJS.T,[gD,this.lc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vu,this.offset],null),new $CLJS.S(null,2,5,$CLJS.T,[qA,this.ia],null),new $CLJS.S(null,2,5,$CLJS.T,[pA,this.ha],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,6,new $CLJS.S(null,6,5,$CLJS.T,[kA,xA,gD,$CLJS.Vu,qA,pA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 6+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1602780238^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.la,b.la)&&$CLJS.F.h(this.lc,b.lc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.ia,b.ia)&&$CLJS.F.h(this.ha,b.ha)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,6,[$CLJS.Vu,null,pA,null,gD,null,xA,null,kA,null,qA,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new jA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(kA,b):$CLJS.O.call(null,kA,b))?new jA(c,this.la,this.lc,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(xA,b):$CLJS.O.call(null,xA,b))?new jA(this.ja,c,this.lc,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(gD,b):$CLJS.O.call(null,gD,b))?new jA(this.ja,this.la,c,this.offset,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Vu,b):$CLJS.O.call(null,$CLJS.Vu,b))?new jA(this.ja,
this.la,this.lc,c,this.ia,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(qA,b):$CLJS.O.call(null,qA,b))?new jA(this.ja,this.la,this.lc,this.offset,c,this.ha,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(pA,b):$CLJS.O.call(null,pA,b))?new jA(this.ja,this.la,this.lc,this.offset,this.ia,c,this.F,this.m,null):new jA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.Je(kA,this.ja),new $CLJS.Je(xA,this.la),new $CLJS.Je(gD,this.lc),new $CLJS.Je($CLJS.Vu,this.offset),new $CLJS.Je(qA,this.ia),new $CLJS.Je(pA,this.ha)],null),this.m))};$CLJS.h.O=function(a,b){return new jA(this.ja,this.la,this.lc,this.offset,this.ia,this.ha,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
var nA=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("cljs.pprint","write-token"),function(f,g){return kA.g(g)},$CLJS.Pj,e,a,b,c,d)}();
nA.o(null,TA,function(a,b){var c=RA.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g($CLJS.SA):c.call(null,$CLJS.SA));b=xA.g(b);c=jD.g(b);$CLJS.m(c)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),c);a=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(a))),$CLJS.$z);$CLJS.gg(vA.g(b),a);return $CLJS.gg(zA.g(b),a)});nA.o(null,XA,function(a,b){var c=RA.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g(WA):c.call(null,WA));b=VA.g(xA.g(b));return $CLJS.m(b)?$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),b):null});
nA.o(null,nB,function(a,b){var c=xA.g(b),d=zA.g(c),e=$CLJS.Vu.g(b);b=gD.g(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(lB,b):$CLJS.F.call(null,lB,b)))a=$CLJS.r(vA.g(c));else if($CLJS.m($CLJS.F.h?$CLJS.F.h($CLJS.mB,b):$CLJS.F.call(null,$CLJS.mB,b)))a=$CLJS.Yz(bA.g($CLJS.r($CLJS.r(a))),$CLJS.$z);else throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));return $CLJS.gg(d,e+a)});nA.o(null,JA,function(a,b){return $CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),$CLJS.bl.g(b))});
nA.o(null,lA,function(a,b){var c=$CLJS.F.h($CLJS.Wk.g(b),gB);c||(c=(c=!$CLJS.F.h($CLJS.Wk.g(b),iB))?$CLJS.r(sA.g(xA.g(b))):c);$CLJS.m(c)?BA.h?BA.h(a,b):BA.call(null,a,b):(b=mA.g($CLJS.r($CLJS.r(a))),$CLJS.m(b)&&$CLJS.Qc(bA.g($CLJS.r($CLJS.r(a))),b));return $CLJS.dj.D($CLJS.r(a),$CLJS.U,mA,null)});
var sD=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("cljs.pprint","emit-nl?"),function(f){return $CLJS.Wk.g(f)},$CLJS.Pj,e,a,b,c,d)}();sD.o(null,jB,function(a,b,c){a=xA.g(a);return tA(b,a,c)});sD.o(null,hB,function(a,b,c){a=xA.g(a);return wA(b,a,c)});
sD.o(null,iB,function(a,b,c,d){a=xA.g(a);var e=$CLJS.r(AA.g(a));return $CLJS.m(e)?e:(d=!rA(b,d))?d:wA(b,a,c)});sD.o(null,gB,function(){return!0});
var CA=function CA(a,b){var d=cda(b);b=$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null);$CLJS.m(b)&&oA(a,b,!1);if($CLJS.m(e)){d=ada(e);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var f=$CLJS.C(e),g=sD.D(f,a,b,bda(e));$CLJS.m(g)&&(BA(a,f),e=$CLJS.D(e));rA(a,e)?a=e:(e=CA.h?CA.h(a,b):CA.call(null,a,b),$CLJS.F.h(e,b)?(oA(a,b,!1),a=d):a=$CLJS.oh.h($CLJS.Lg,$CLJS.qg.h(e,d)));return a}return null};$CLJS.h=MA.prototype;$CLJS.h.O=function(a,b){return new MA(this.La,this.Bg,this.fj,this.ei,this.Hd,b)};$CLJS.h.N=function(){return this.Wi};
$CLJS.h.$b=function(){return this.Hd};
$CLJS.h.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a))){var c=LA(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.dk.g($CLJS.r($CLJS.r(this)));if($CLJS.F.h(d,KA))return FA(this),$CLJS.Qc(bA.g($CLJS.r($CLJS.r(this))),b),$CLJS.dj.D($CLJS.r(this),$CLJS.U,mA,a);d=IA.g($CLJS.r($CLJS.r(this)));c=d+$CLJS.E(c);$CLJS.dj.D($CLJS.r(this),$CLJS.U,IA,c);return EA(this,new fA(JA,b,a,d,c,null,null,null))}if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(this))),KA)?(FA(this),b=$CLJS.Qc(bA.g($CLJS.r($CLJS.r(this))),b)):$CLJS.F.h(b,"\n")?b=LA(this,"\n"):(a=IA.g($CLJS.r($CLJS.r(this))),c=a+1,$CLJS.dj.D($CLJS.r(this),$CLJS.U,IA,c),b=$CLJS.Eu(b),b=EA(this,new fA(JA,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};$CLJS.h.Sc=function(){this.ig(null);return Kz(bA.g($CLJS.r($CLJS.r(this))))};
$CLJS.h.ig=function(){return $CLJS.F.h($CLJS.dk.g($CLJS.r($CLJS.r(this))),HA)?(oA(this,$CLJS.Su.g($CLJS.r($CLJS.r(this))),!0),$CLJS.dj.D($CLJS.r(this),$CLJS.U,$CLJS.Su,$CLJS.Lg)):FA(this)};$CLJS.aB=!0;bB=null;$CLJS.NA=72;OA=40;tD=null;uD=null;vD=null;JB=null;IB=10;eB=0;$A=null;wD=function wD(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wD.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
wD.l=function(a,b){var c=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,1,[pD,!0],null),$CLJS.R.h(fD,b)]));b=IB;var d=uD,e=$CLJS.yb,f=$CLJS.Gi,g=tD,l=OA,n=bB,q=$CLJS.aB,u=JB,v=$CLJS.ub,x=$CLJS.NA,y=vD,B=bA.h(c,IB),H=zea.h(c,uD),I=$CLJS.iD.h(c,$CLJS.yb),Q=$CLJS.Yu.h(c,$CLJS.Gi),Y=uea.h(c,tD),aa=uA.h(c,OA),ha=$CLJS.wk.h(c,bB),qa=rD.h(c,$CLJS.aB),Ea=kea.h(c,JB),jb=$CLJS.tb.h(c,$CLJS.ub),lb=sea.h(c,$CLJS.NA),Fb=Dea.h(c,vD);IB=B;uD=H;$CLJS.yb=I;$CLJS.Gi=Q;tD=Y;OA=aa;bB=ha;$CLJS.aB=qa;JB=Ea;$CLJS.ub=jb;$CLJS.NA=
lb;vD=Fb;try{var Mb=new $CLJS.rb,ac=$CLJS.Ie(c,pD)?pD.g(c):!0,Tb=!0===ac||null==ac?new $CLJS.gd(Mb):ac;if($CLJS.m($CLJS.aB)){var Ub=$CLJS.Gb($CLJS.ZA(Tb));c=$CLJS.Rz;$CLJS.Rz=Ub?$CLJS.QA(Tb):Tb;try{$CLJS.cB(a),$CLJS.Xz()}finally{$CLJS.Rz=c}}else{Ub=$CLJS.Rz;$CLJS.Rz=Tb;try{Qz.call(null,a)}finally{$CLJS.Rz=Ub}}!0===ac&&$CLJS.Nz($CLJS.p.g(Mb));return null==ac?$CLJS.p.g(Mb):null}finally{vD=y,$CLJS.NA=x,$CLJS.ub=v,JB=u,$CLJS.aB=q,bB=n,OA=l,tD=g,$CLJS.Gi=f,$CLJS.yb=e,uD=d,IB=b}};wD.v=1;
wD.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var uB=null;$CLJS.h=wB.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "seq":return this.Wc;case "rest":return this.xb;case "pos":return this.uc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[xB,this.Wc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,this.xb],null),new $CLJS.S(null,2,5,$CLJS.T,[IA,this.uc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,3,new $CLJS.S(null,3,5,$CLJS.T,[xB,$CLJS.xk,IA],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};
$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 3+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-402038447^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Wc,b.Wc)&&$CLJS.F.h(this.xb,b.xb)&&$CLJS.F.h(this.uc,b.uc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,3,[IA,null,xB,null,$CLJS.xk,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new wB(this.Wc,this.xb,this.uc,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(xB,b):$CLJS.O.call(null,xB,b))?new wB(c,this.xb,this.uc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.xk,b):$CLJS.O.call(null,$CLJS.xk,b))?new wB(this.Wc,c,this.uc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(IA,b):$CLJS.O.call(null,IA,b))?new wB(this.Wc,this.xb,c,this.F,this.m,null):new wB(this.Wc,this.xb,this.uc,this.F,$CLJS.U.j(this.m,b,c),null)};
$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.Je(xB,this.Wc),new $CLJS.Je($CLJS.xk,this.xb),new $CLJS.Je(IA,this.uc)],null),this.m))};$CLJS.h.O=function(a,b){return new wB(this.Wc,this.xb,this.uc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h=CB.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "func":return this.Ac;case "def":return this.yc;case "params":return this.Hc;case "offset":return this.offset;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[hC,this.Ac],null),new $CLJS.S(null,2,5,$CLJS.T,[MC,this.yc],null),new $CLJS.S(null,2,5,$CLJS.T,[fC,this.Hc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vu,this.offset],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[hC,MC,fC,$CLJS.Vu],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-829256337^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Ac,b.Ac)&&$CLJS.F.h(this.yc,b.yc)&&$CLJS.F.h(this.Hc,b.Hc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Vu,null,hC,null,fC,null,MC,null],null),null),b)?$CLJS.Dm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new CB(this.Ac,this.yc,this.Hc,this.offset,this.F,$CLJS.Rf($CLJS.Dm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h(hC,b):$CLJS.O.call(null,hC,b))?new CB(c,this.yc,this.Hc,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(MC,b):$CLJS.O.call(null,MC,b))?new CB(this.Ac,c,this.Hc,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(fC,b):$CLJS.O.call(null,fC,b))?new CB(this.Ac,this.yc,c,this.offset,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Vu,b):$CLJS.O.call(null,$CLJS.Vu,b))?new CB(this.Ac,this.yc,this.Hc,c,this.F,this.m,null):
new CB(this.Ac,this.yc,this.Hc,this.offset,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je(hC,this.Ac),new $CLJS.Je(MC,this.yc),new $CLJS.Je(fC,this.Hc),new $CLJS.Je($CLJS.Vu,this.offset)],null),this.m))};$CLJS.h.O=function(a,b){return new CB(this.Ac,this.yc,this.Hc,this.offset,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
var hda=new $CLJS.k(null,3,[2,"#b",8,"#o",16,"#x"],null),WB=new $CLJS.S(null,20,5,$CLJS.T,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),xD=new $CLJS.S(null,20,5,$CLJS.T,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),XB=new $CLJS.S(null,10,5,$CLJS.T,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),Eea=new $CLJS.S(null,10,5,$CLJS.T,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),ZB=new $CLJS.S(null,22,5,$CLJS.T," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),Fea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),Gea=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,9,5,$CLJS.T,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.S(null,9,5,$CLJS.T,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.S(null,3,5,$CLJS.T,["M","MM","MMM"],null)],null),jda=new $CLJS.k(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);CC.prototype.O=function(a,b){return new CC(this.La,b)};CC.prototype.N=function(){return this.Xi};CC.prototype.Sc=function(){return Kz(this.La)};
CC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.La,$CLJS.Eu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};DC.prototype.O=function(a,b){return new DC(this.La,b)};DC.prototype.N=function(){return this.Yi};DC.prototype.Sc=function(){return Kz(this.La)};
DC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,b.toUpperCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Qc(this.La,$CLJS.Eu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};EC.prototype.O=function(a,b){return new EC(this.La,this.we,b)};EC.prototype.N=function(){return this.Zi};EC.prototype.Sc=function(){return Kz(this.La)};
EC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Qc(this.La,Bda(b.toLowerCase(),$CLJS.r(this.we))),0<b.length?$CLJS.gg(this.we,$CLJS.Sa($CLJS.Td(b,$CLJS.E(b)-1))):null;if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.Eu(b),a=$CLJS.m($CLJS.r(this.we))?b.toUpperCase():b,$CLJS.Qc(this.La,a),$CLJS.gg(this.we,$CLJS.Sa(b));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
FC.prototype.O=function(a,b){return new FC(this.La,this.ke,b)};FC.prototype.N=function(){return this.$i};FC.prototype.Sc=function(){return Kz(this.La)};
FC.prototype.qd=function(a,b){a=$CLJS.Ob(b);if($CLJS.m($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Gb($CLJS.r(this.ke))?(a=RegExp("\\S","g").exec(b),a=$CLJS.m(a)?a.index:a,$CLJS.m(a)?($CLJS.Qc(this.La,[b.substring(0,a),$CLJS.Td(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.gg(this.ke,!0)):$CLJS.Qc(this.La,b)):$CLJS.Qc(this.La,b.toLowerCase());if($CLJS.m($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=$CLJS.Eu(b),
a=$CLJS.Gb($CLJS.r(this.ke)),$CLJS.m(a?Iz(b):a)?($CLJS.gg(this.ke,!0),$CLJS.Qc(this.La,b.toUpperCase())):$CLJS.Qc(this.La,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
var Oda=$CLJS.Sh("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.k(null,5,[HC,"A",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),NB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),LB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return QB($CLJS.UB,a,b)}}],null),new $CLJS.k(null,
5,[HC,"S",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),NB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),LB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return QB($CLJS.aj,a,b)}}],null),new $CLJS.k(null,5,[HC,"D",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,
2,5,$CLJS.T,[" ",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),SB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return VB(10,a,b)}}],null),new $CLJS.k(null,5,[HC,"B",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),SB,new $CLJS.S(null,
2,5,$CLJS.T,[3,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return VB(2,a,b)}}],null),new $CLJS.k(null,5,[HC,"O",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),SB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,
null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return VB(8,a,b)}}],null),new $CLJS.k(null,5,[HC,"X",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),SB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){return VB(16,a,b)}}],null),new $CLJS.k(null,
5,[HC,"R",fC,new $CLJS.k(null,5,[bA,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),TB,new $CLJS.S(null,2,5,$CLJS.T,[",",String],null),SB,new $CLJS.S(null,2,5,$CLJS.T,[3,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(a){return $CLJS.m($CLJS.C(bA.g(a)))?function(b,c){return VB(bA.g(b),b,c)}:$CLJS.m(function(){var b=EB.g(a);return $CLJS.m(b)?
DB.g(a):b}())?function(b,c){return aC(Fea,c)}:$CLJS.m(EB.g(a))?function(b,c){return aC(Gea,c)}:$CLJS.m(DB.g(a))?function(b,c){b=yB(c);c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);if($CLJS.F.h(0,c))PB.l($CLJS.G(["zeroth"]));else{var d=RB(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(ZB)){var e=$CLJS.rg.h(YB,$CLJS.Lu(1,d));e=$B(e,1);var f=$CLJS.$d(d);d=$CLJS.af(f,100);f=Mz(f,100);var g=0<d?[$CLJS.p.g($CLJS.Td(WB,d))," hundred"].join(""):null,l=$CLJS.p,n=l.g;if(0<f)if(20>f)var q=$CLJS.Td(xD,f);else{q=$CLJS.af(f,10);
var u=Mz(f,10);q=0<q&&!(0<u)?$CLJS.Td(Eea,q):[$CLJS.p.g(0<q?$CLJS.Td(XB,q):null),0<q&&0<u?"-":null,$CLJS.p.g(0<u?$CLJS.Td(xD,u):null)].join("")}else q=0<d?"th":null;d=[g,0<d&&0<f?" ":null,n.call(l,q)].join("");PB.l($CLJS.G([[0>c?"minus ":null,$CLJS.je(e)||$CLJS.je(d)?$CLJS.je(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else VB(10,new $CLJS.k(null,5,[MB,0,OB," ",TB,",",SB,3,DB,!0],null),qB(new $CLJS.S(null,1,5,$CLJS.T,[c],null))),e=Mz(c,100),c=11<e||19>e,e=Mz(e,10),PB.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=yB(c);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);if($CLJS.F.h(0,b))PB.l($CLJS.G(["zero"]));else{var d=RB(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(ZB)?(d=$CLJS.rg.h(YB,d),d=$B(d,0),PB.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):VB(10,new $CLJS.k(null,5,[MB,0,OB," ",TB,",",SB,3,DB,!0],null),qB(new $CLJS.S(null,1,5,$CLJS.T,[b],null)))}return c}}],null),new $CLJS.k(null,5,[HC,"P",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,
null,IC,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){b=$CLJS.m(DB.g(a))?AB(b,-1):b;a=$CLJS.m(EB.g(a))?new $CLJS.S(null,2,5,$CLJS.T,["y","ies"],null):new $CLJS.S(null,2,5,$CLJS.T,["","s"],null);var c=yB(b);b=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);PB.l($CLJS.G([$CLJS.F.h(b,1)?$CLJS.C(a):$CLJS.Zd(a)]));return c}}],null),new $CLJS.k(null,5,[HC,"C",fC,new $CLJS.k(null,1,[bC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,
IC,null],null),null),LC,$CLJS.P,JC,function(a){return $CLJS.m(DB.g(a))?kda:$CLJS.m(EB.g(a))?lda:mda}],null),new $CLJS.k(null,5,[HC,"F",fC,new $CLJS.k(null,5,[mC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),LC,$CLJS.P,JC,
function(){return pC}],null),new $CLJS.k(null,5,[HC,"E",fC,new $CLJS.k(null,7,[mC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),qC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],
null),null),LC,$CLJS.P,JC,function(){return sC}],null),new $CLJS.k(null,5,[HC,"G",fC,new $CLJS.k(null,7,[mC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),nC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),qC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),$CLJS.Zu,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),oC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null),rC,new $CLJS.S(null,2,5,$CLJS.T,[null,String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,
1,[EB,null],null),null),LC,$CLJS.P,JC,function(){return oda}],null),new $CLJS.k(null,5,[HC,"$",fC,new $CLJS.k(null,4,[nC,new $CLJS.S(null,2,5,$CLJS.T,[2,Number],null),$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),mC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return pda}],null),new $CLJS.k(null,5,[HC,"%",fC,new $CLJS.k(null,1,[$CLJS.kD,
new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,$CLJS.oi,LC,$CLJS.P,JC,function(){return function(a,b){a=$CLJS.kD.g(a);for(var c=0;;)if(c<a)Sz(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[HC,"\x26",fC,new $CLJS.k(null,1,[$CLJS.kD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[rD,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){a=$CLJS.kD.g(a);0<a&&((null!=$CLJS.Rz?$CLJS.Rz.C&32768||$CLJS.t===$CLJS.Rz.Zf||($CLJS.Rz.C?
0:$CLJS.Nb($CLJS.Jz,$CLJS.Rz)):$CLJS.Nb($CLJS.Jz,$CLJS.Rz))?$CLJS.F.h(0,$CLJS.Yz(bA.g($CLJS.r($CLJS.r($CLJS.Rz))),$CLJS.$z))||Sz():Sz());--a;for(var c=0;;)if(c<a)Sz(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[HC,"|",fC,new $CLJS.k(null,1,[$CLJS.kD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,$CLJS.oi,LC,$CLJS.P,JC,function(){return function(a,b){a=$CLJS.kD.g(a);for(var c=0;;)if(c<a)PB.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[HC,"~",fC,new $CLJS.k(null,
1,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,$CLJS.oi,LC,$CLJS.P,JC,function(){return function(a,b){a=$CLJS.tC.g(a);PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(a,"~"))]));return b}}],null),new $CLJS.k(null,5,[HC,"\n",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,2,[DB,null,EB,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){$CLJS.m(EB.g(a))&&Sz();return b}}],null),new $CLJS.k(null,5,[HC,"T",fC,new $CLJS.k(null,2,[hD,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),
NB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,2,[EB,null,rD,null],null),null),LC,$CLJS.P,JC,function(a){return $CLJS.m(EB.g(a))?function(b,c){var d=hD.g(b);b=NB.g(b);var e=d+$CLJS.Yz(bA.g($CLJS.r($CLJS.r($CLJS.Rz))),$CLJS.$z);e=0<b?Mz(e,b):0;d+=$CLJS.F.h(0,e)?0:b-e;PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(d," "))]));return c}:function(b,c){var d=hD.g(b);b=NB.g(b);var e=$CLJS.Yz(bA.g($CLJS.r($CLJS.r($CLJS.Rz))),$CLJS.$z);d=e<d?d-e:$CLJS.F.h(b,0)?0:b-
Mz(e-d,b);PB.l($CLJS.G([$CLJS.R.h($CLJS.p,$CLJS.ng(d," "))]));return c}}],null),new $CLJS.k(null,5,[HC,"*",fC,new $CLJS.k(null,1,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,2,[DB,null,EB,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){var c=$CLJS.tC.g(a);return $CLJS.m(EB.g(a))?BB(b,c):AB(b,$CLJS.m(DB.g(a))?-c:c)}}],null),new $CLJS.k(null,5,[HC,"?",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,1,[EB,null],null),null),LC,$CLJS.P,
JC,function(a){return $CLJS.m(EB.g(a))?function(b,c){var d=zB(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return iC(c,d,gC.g(b))}:function(b,c){var d=zB(c);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);var e=yB(d);d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);d=qB(d);iC(c,d,gC.g(b));return e}}],null),new $CLJS.k(null,5,[HC,"(",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,new $CLJS.k(null,3,[$CLJS.OC,")",SC,null,$CLJS.Tu,null],null),JC,function(a){var b=$CLJS.m(function(){var c=
EB.g(a);return $CLJS.m(c)?DB.g(a):c}())?Ada:$CLJS.m(DB.g(a))?Cda:$CLJS.m(EB.g(a))?Dda:zda;return function(c,d){a:{var e=$CLJS.C($CLJS.vC.g(c)),f=$CLJS.Rz;$CLJS.Rz=b.g?b.g($CLJS.Rz):b.call(null,$CLJS.Rz);try{var g=iC(e,d,gC.g(c));break a}finally{$CLJS.Rz=f}g=void 0}return g}}],null),new $CLJS.k(null,5,[HC,")",fC,$CLJS.P,GC,$CLJS.oi,LC,$CLJS.P,JC,function(){return null}],null),new $CLJS.k(null,5,[HC,"[",fC,new $CLJS.k(null,1,[uC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),GC,new $CLJS.ni(null,
new $CLJS.k(null,2,[DB,null,EB,null],null),null),LC,new $CLJS.k(null,3,[$CLJS.OC,"]",SC,!0,$CLJS.Tu,$CLJS.oD],null),JC,function(a){return $CLJS.m(DB.g(a))?rda:$CLJS.m(EB.g(a))?sda:qda}],null),new $CLJS.k(null,5,[HC,";",fC,new $CLJS.k(null,2,[AC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),BC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[DB,null],null),null),LC,new $CLJS.k(null,1,[$CLJS.Xu,!0],null),JC,function(){return null}],null),new $CLJS.k(null,
5,[HC,"]",fC,$CLJS.P,GC,$CLJS.oi,LC,$CLJS.P,JC,function(){return null}],null),new $CLJS.k(null,5,[HC,"{",fC,new $CLJS.k(null,1,[wC,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,new $CLJS.k(null,2,[$CLJS.OC,"}",SC,!1],null),JC,function(a){var b=EB.g(a);b=$CLJS.m(b)?DB.g(a):b;return $CLJS.m(b)?wda:$CLJS.m(DB.g(a))?uda:$CLJS.m(EB.g(a))?vda:tda}],null),new $CLJS.k(null,5,[HC,"}",fC,$CLJS.P,GC,new $CLJS.ni(null,
new $CLJS.k(null,1,[DB,null],null),null),LC,$CLJS.P,JC,function(){return null}],null),new $CLJS.k(null,5,[HC,"\x3c",fC,new $CLJS.k(null,4,[MB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),NB,new $CLJS.S(null,2,5,$CLJS.T,[1,Number],null),LB,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null),OB,new $CLJS.S(null,2,5,$CLJS.T,[" ",String],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,4,[DB,null,EB,null,IC,null,rD,null],null),null),LC,new $CLJS.k(null,3,[$CLJS.OC,"\x3e",SC,!0,$CLJS.Tu,RC],null),JC,function(){return yda}],
null),new $CLJS.k(null,5,[HC,"\x3e",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,1,[DB,null],null),null),LC,$CLJS.P,JC,function(){return null}],null),new $CLJS.k(null,5,[HC,"^",fC,new $CLJS.k(null,3,[lD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),nD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null),mD,new $CLJS.S(null,2,5,$CLJS.T,[null,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[DB,null],null),null),LC,$CLJS.P,JC,function(){return function(a,b){var c=lD.g(a),d=nD.g(a),e=
mD.g(a),f=$CLJS.m(DB.g(a))?dC:cC;return $CLJS.m($CLJS.m(c)?$CLJS.m(d)?e:d:c)?c<=d&&d<=e?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m($CLJS.m(c)?d:c)?$CLJS.F.h(c,d)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:$CLJS.m(c)?$CLJS.F.h(c,0)?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b:($CLJS.m(DB.g(a))?$CLJS.je($CLJS.xk.g(gC.g(a))):$CLJS.je($CLJS.xk.g(b)))?new $CLJS.S(null,2,5,$CLJS.T,[f,b],null):b}}],null),new $CLJS.k(null,5,[HC,"W",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,4,[DB,null,EB,null,
IC,null,rD,null],null),null),LC,$CLJS.P,JC,function(a){if($CLJS.m(function(){var c=EB.g(a);return $CLJS.m(c)?c:DB.g(a)}())){var b=$CLJS.qg.h($CLJS.m(EB.g(a))?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yu,null,$CLJS.iD,null],null):$CLJS.Lg,$CLJS.m(DB.g(a))?new $CLJS.S(null,2,5,$CLJS.T,[rD,!0],null):$CLJS.Lg);return function(c,d){d=yB(d);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.m($CLJS.R.j(wD,c,b))?new $CLJS.S(null,2,5,$CLJS.T,[cC,d],null):d}}return function(c,d){d=yB(d);c=$CLJS.J(d,0,null);d=
$CLJS.J(d,1,null);return $CLJS.m($CLJS.cB(c))?new $CLJS.S(null,2,5,$CLJS.T,[cC,d],null):d}}],null),new $CLJS.k(null,5,[HC,"_",fC,$CLJS.P,GC,new $CLJS.ni(null,new $CLJS.k(null,3,[DB,null,EB,null,IC,null],null),null),LC,$CLJS.P,JC,function(){return Fda}],null),new $CLJS.k(null,5,[HC,"I",fC,new $CLJS.k(null,1,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[0,Number],null)],null),GC,new $CLJS.ni(null,new $CLJS.k(null,1,[DB,null],null),null),LC,$CLJS.P,JC,function(){return Eda}],null)]),Gda=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
Mda=new $CLJS.ni(null,new $CLJS.k(null,2,[GB,null,FB,null],null),null),Jda=new $CLJS.k(null,2,[":",DB,"@",EB],null),rB=function rB(a){for(;;){if($CLJS.je(a))return!1;var c=rD.g(GC.g(MC.g($CLJS.C(a))));$CLJS.m(c)||(c=$CLJS.Xf(rB,$CLJS.C($CLJS.vC.g(fC.g($CLJS.C(a))))),c=$CLJS.m(c)?c:$CLJS.Xf(rB,$CLJS.C($CLJS.Tu.g(fC.g($CLJS.C(a))))));if($CLJS.m(c))return!0;a=$CLJS.D(a)}},bD=$CLJS.ej(pB),TC=new $CLJS.k(null,6,[$CLJS.Fl,"'",$CLJS.Jl,"#'",$CLJS.gba,"@",$CLJS.hba,"~",nea,"@",lea,"~"],null);
(function(){var a=bD("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=qB(d);return sB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()})();
var yD=function(){var a=bD("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=qB(d);return sB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),zD=new $CLJS.k(null,2,["core$future_call","Future","core$promise","Promise"],null),Hea=function(){var a=bD("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=qB(d);return sB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),AD,Iea=$CLJS.cj.g($CLJS.P),Jea=$CLJS.cj.g($CLJS.P),Kea=$CLJS.cj.g($CLJS.P),Lea=$CLJS.cj.g($CLJS.P),Mea=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
AD=new $CLJS.vj($CLJS.Ui.h("cljs.pprint","simple-dispatch"),$C,$CLJS.Pj,Mea,Iea,Jea,Kea,Lea);Oz(AD,$CLJS.pl,function(a){if($CLJS.Gb(UC(a)))if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("(",")");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e&&($CLJS.cB($CLJS.C(e)),$CLJS.D(e))){$CLJS.Qc($CLJS.Rz," ");kB(jB);a=d+1;var f=$CLJS.D(e);d=a;e=f;continue}}else $CLJS.Qc($CLJS.Rz,"...");break}YA()}finally{$A=c,eB=b}}return null});Oz(AD,$CLJS.zl,VC);
Oz(AD,$CLJS.Xl,WC);Oz(AD,$CLJS.Hk,yD);Oz(AD,null,function(){return $CLJS.Qc($CLJS.Rz,$CLJS.aj.l($CLJS.G([null])))});Oz(AD,$CLJS.Pj,XC);bB=AD;
var BD=function(){var a=bD("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=qB(d);return sB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),CD=function(){var a=bD("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.z(f,0,null)}return c.call(this,e)}function c(d){d=qB(d);return sB(a,d)}b.v=0;b.B=function(d){d=$CLJS.A(d);return c(d)};b.l=c;return b}()}(),DD=$CLJS.P,Nea=function(a){return $CLJS.oh.h($CLJS.P,$CLJS.sg($CLJS.Ye,$CLJS.G([function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=new $CLJS.S(null,
2,5,$CLJS.T,[q,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.ui($CLJS.C(q))),$CLJS.Zd(q)],null)],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.ui($CLJS.C(l))),$CLJS.Zd(l)],null)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.oh.h($CLJS.P,$CLJS.rg.h(function(b){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,
1,null);var e=$CLJS.qf(c);e=$CLJS.m(e)?e:$CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,24,[$CLJS.Ik,"null",$CLJS.$k,"null",$CLJS.fm,"null",$CLJS.km,"null",$CLJS.Vk,"null",$CLJS.Bl,"null",$CLJS.wl,"null",$CLJS.Cl,"null",$CLJS.Vj,"null",$CLJS.Ml,"null",$CLJS.ol,"null",$CLJS.dl,"null",$CLJS.Tl,"null",$CLJS.im,"null",$CLJS.zk,"null",$CLJS.uk,"null",$CLJS.Sk,"null",$CLJS.hl,"null",$CLJS.Jk,"null",$CLJS.Jl,"null",$CLJS.Fl,"null",$CLJS.Zl,"null",$CLJS.rk,"null",$CLJS.Wl,"null"],null),null),c);return $CLJS.Gb(e)?
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.h("clojure.core",$CLJS.ui(c)),d],null):b},a))}($CLJS.Sh([$CLJS.Jk,$CLJS.uk,dea,$CLJS.Ml,iea,aea,cea,jea,gea,fea,hea,vea,pea,$CLJS.Wl,oea,tea,rea,Bea,Zda,$CLJS.dl,wea,xea,Aea,$CLJS.Gl,mea,Cea,$da,bea,eea,yea],[BD,function(a){var b=$CLJS.Zd(a),c=$CLJS.C($CLJS.zd($CLJS.zd(a)));if($CLJS.qe(b)){a=DD;DD=$CLJS.F.h(1,$CLJS.E(b))?$CLJS.Pf([$CLJS.C(b),"%"]):$CLJS.oh.h($CLJS.P,$CLJS.rg.j(function(d,e){return new $CLJS.S(null,2,5,$CLJS.T,[d,["%",$CLJS.p.g(e)].join("")],
null)},b,$CLJS.Ai(1,$CLJS.E(b)+1)));try{return function(){var d=bD("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(g){var l=null;if(0<arguments.length){l=0;for(var n=Array(arguments.length-0);l<n.length;)n[l]=arguments[l+0],++l;l=new $CLJS.z(n,0,null)}return f.call(this,l)}function f(g){g=qB(g);return sB(d,g)}e.v=0;e.B=function(g){g=$CLJS.A(g);return f(g)};e.l=f;return e}()}()(c)}finally{DD=a}}else return cD(a)},eD,CD,function(a){if(3<$CLJS.E(a)){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,
"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("(",")");oB(lB,1);$CLJS.R.h(function(){var l=bD("~w ~@_~w ~@_~w ~_");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.z(x,0,null)}return q.call(this,v)}function q(u){u=qB(u);return sB(l,u)}n.v=0;n.B=function(u){u=$CLJS.A(u);return q(u)};n.l=q;return n}()}(),a);for(var d=0,e=$CLJS.A($CLJS.kg(3,a));;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,
"#");else{a=eB;var f=$A;eB+=1;$A=0;try{UA(null,null),$CLJS.cB($CLJS.C(e)),$CLJS.D(e)&&($CLJS.Qc($CLJS.Rz," "),kB(hB),$CLJS.cB($CLJS.Zd(e))),YA()}finally{$A=f,eB=a}}if($CLJS.D($CLJS.zd(e))){$CLJS.Qc($CLJS.Rz," ");kB(jB);a=d+1;var g=$CLJS.D($CLJS.zd(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.Rz,"...");break}YA()}finally{$A=c,eB=b}}return null}return cD(a)},BD,dD,dD,eD,BD,eD,CD,CD,BD,CD,eD,eD,BD,eD,function(a){if($CLJS.D(a)){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=
"string"===typeof $CLJS.C(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(c),$CLJS.D(c)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,c],null);c=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.oe($CLJS.C(d))?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(d),$CLJS.D(d)],null):new $CLJS.S(null,2,5,$CLJS.T,[null,d],null);var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{d=eB;var g=$A;eB+=1;$A=0;try{UA("(",")");(function(){var n=bD("~w ~1I~@_~w");return function(){function q(v){var x=
null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,x)}function u(v){v=qB(v);return sB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()})()(a,b);$CLJS.m($CLJS.m(c)?c:$CLJS.m(e)?e:$CLJS.A(f))&&function(){var n=bD("~@:_");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,
x)}function u(v){v=qB(v);return sB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};q.l=u;return q}()}()();$CLJS.m(c)&&tB(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.m(e)?e:$CLJS.A(f)]));$CLJS.m(e)&&function(){var n=bD("~w~:[~;~:@_~]");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.z(y,0,null)}return u.call(this,x)}function u(v){v=qB(v);return sB(n,v)}q.v=0;q.B=function(v){v=$CLJS.A(v);return u(v)};
q.l=u;return q}()}()(e,$CLJS.A(f));for(a=f;;){Vda($CLJS.C(a));var l=$CLJS.D(a);if(l)b=l,kB(jB),a=b;else break}YA()}finally{$A=g,eB=d}}return null}return $CLJS.cB(a)},eD,function(a){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{var b=eB,c=$A;eB+=1;$A=0;try{UA("(",")");oB(lB,1);$CLJS.cB($CLJS.C(a));if($CLJS.D(a)){$CLJS.Qc($CLJS.Rz," ");kB(jB);for(var d=0,e=$CLJS.D(a);;){if($CLJS.Gb($CLJS.yb)||d<$CLJS.yb){if(e){if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{a=eB;var f=$A;eB+=1;$A=0;try{UA(null,null),$CLJS.cB($CLJS.C(e)),
$CLJS.D(e)&&($CLJS.Qc($CLJS.Rz," "),kB(hB),$CLJS.cB($CLJS.Zd(e))),YA()}finally{$A=f,eB=a}}if($CLJS.D($CLJS.zd(e))){$CLJS.Qc($CLJS.Rz," ");kB(jB);a=d+1;var g=$CLJS.D($CLJS.zd(e));d=a;e=g;continue}}}else $CLJS.Qc($CLJS.Rz,"...");break}}YA()}finally{$A=c,eB=b}}return null},eD,dD,dD,BD,BD,eD,eD,BD]))),ED,Oea=$CLJS.cj.g($CLJS.P),Pea=$CLJS.cj.g($CLJS.P),Qea=$CLJS.cj.g($CLJS.P),Rea=$CLJS.cj.g($CLJS.P),Sea=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
ED=new $CLJS.vj($CLJS.Ui.h("cljs.pprint","code-dispatch"),$C,$CLJS.Pj,Sea,Oea,Pea,Qea,Rea);Oz(ED,$CLJS.pl,function(a){if($CLJS.Gb(UC(a))){var b=Nea.call(null,$CLJS.C(a));return $CLJS.m(b)?b.g?b.g(a):b.call(null,a):cD(a)}return null});Oz(ED,$CLJS.nk,function(a){var b=a.g?a.g(DD):a.call(null,DD);return $CLJS.m(b)?PB.l($CLJS.G([b])):$CLJS.m(vD)?PB.l($CLJS.G([$CLJS.ui(a)])):Qz.call(null,a)});Oz(ED,$CLJS.zl,VC);Oz(ED,$CLJS.Xl,WC);Oz(ED,$CLJS.Hk,yD);Oz(ED,YC,Hea);
Oz(ED,ZC,function(a){var b=$CLJS.p,c=b.g,d=$CLJS.Ob(a).name;var e=$CLJS.Ei(/^[^$]+\$[^$]+/,d);e=$CLJS.m(e)?zD.g?zD.g(e):zD.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.m(e)?e:d),"@",$CLJS.p.g($CLJS.Qa(a)),": "].join("");if($CLJS.m(fB()))$CLJS.Qc($CLJS.Rz,"#");else{c=eB;d=$A;eB+=1;$A=0;try{UA(b,"\x3e");oB(lB,-(b.length-2));kB(jB);var f=null!=a?a.K&1||$CLJS.t===a.ak?!0:a.K?!1:$CLJS.Nb(Lz,a):$CLJS.Nb(Lz,a);var g=f?!$CLJS.Cu(a):f;$CLJS.cB(g?qea:$CLJS.r(a));YA()}finally{$A=d,eB=c}}return null});
Oz(ED,null,Qz);Oz(ED,$CLJS.Pj,XC);bB=AD;