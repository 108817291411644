var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var Vma;$CLJS.hY=new $CLJS.N("mbql.filter","operator","mbql.filter/operator",-1267834132);Vma=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);for(var iY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),jY=null,kY=0,lY=0;;)if(lY<kY){var Wma=jY.$(null,lY);$CLJS.AV(Wma,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)],null)],null)],null)]));lY+=1}else{var mY=$CLJS.A(iY);if(mY){var nY=mY;if($CLJS.re(nY)){var oY=$CLJS.$c(nY),Xma=$CLJS.ad(nY),
Yma=oY,Zma=$CLJS.E(oY);iY=Xma;jY=Yma;kY=Zma}else{var $ma=$CLJS.C(nY);$CLJS.AV($ma,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)],null)],null)],null)]));iY=$CLJS.D(nY);jY=null;kY=0}lY=0}else break}$CLJS.yV($CLJS.Tk,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)]));
for(var pY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.CK],null)),qY=null,rY=0,sY=0;;)if(sY<rY){var ana=qY.$(null,sY);$CLJS.AV(ana,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.OL],null)],null)],null)],null)]));sY+=1}else{var tY=$CLJS.A(pY);if(tY){var uY=tY;if($CLJS.re(uY)){var vY=$CLJS.$c(uY),bna=$CLJS.ad(uY),
cna=vY,dna=$CLJS.E(vY);pY=bna;qY=cna;rY=dna}else{var ena=$CLJS.C(uY);$CLJS.AV(ena,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.OL],null)],null)],null)],null)]));pY=$CLJS.D(uY);qY=null;rY=0}sY=0}else break}
for(var wY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.hm,$CLJS.tm,$CLJS.jm,$CLJS.sm],null)),xY=null,yY=0,zY=0;;)if(zY<yY){var fna=xY.$(null,zY);$CLJS.yV(fna,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)]));zY+=1}else{var AY=$CLJS.A(wY);if(AY){var BY=AY;if($CLJS.re(BY)){var CY=$CLJS.$c(BY),gna=$CLJS.ad(BY),hna=CY,ina=$CLJS.E(CY);wY=gna;xY=hna;yY=ina}else{var jna=$CLJS.C(BY);$CLJS.yV(jna,$CLJS.G([$CLJS.xu,$CLJS.Al,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)]));wY=$CLJS.D(BY);xY=null;yY=0}zY=0}else break}$CLJS.yV($CLJS.JI,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)]));
$CLJS.yV($CLJS.UI,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)]));
for(var DY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xI,$CLJS.nJ],null)),EY=null,FY=0,GY=0;;)if(GY<FY){var kna=EY.$(null,GY);$CLJS.yV(kna,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.LL],null)]));GY+=1}else{var HY=$CLJS.A(DY);if(HY){var IY=HY;if($CLJS.re(IY)){var JY=$CLJS.$c(IY),lna=$CLJS.ad(IY),mna=JY,nna=$CLJS.E(JY);DY=lna;EY=mna;FY=nna}else{var ona=$CLJS.C(IY);$CLJS.yV(ona,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.LL],null)]));DY=$CLJS.D(IY);
EY=null;FY=0}GY=0}else break}
for(var KY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KI,$CLJS.$I],null)),LY=null,MY=0,NY=0;;)if(NY<MY){var pna=LY.$(null,NY);$CLJS.yV(pna,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],null)]));NY+=1}else{var OY=$CLJS.A(KY);if(OY){var PY=OY;if($CLJS.re(PY)){var QY=$CLJS.$c(PY),qna=$CLJS.ad(PY),rna=QY,sna=$CLJS.E(QY);KY=qna;LY=rna;MY=sna}else{var tna=$CLJS.C(PY);$CLJS.yV(tna,$CLJS.G([$CLJS.xu,$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],null)]));KY=$CLJS.D(PY);
LY=null;MY=0}NY=0}else break}
for(var RY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$J,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null),SY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ],null)),TY=null,UY=0,VY=0;;)if(VY<UY){var WY=TY.$(null,VY);$CLJS.vV(WY,$CLJS.Al,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,WY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.yL,RY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],null)],null));VY+=1}else{var XY=$CLJS.A(SY);if(XY){var YY=XY;if($CLJS.re(YY)){var ZY=$CLJS.$c(YY),una=$CLJS.ad(YY),vna=ZY,wna=$CLJS.E(ZY);SY=una;TY=vna;UY=wna}else{var $Y=$CLJS.C(YY);$CLJS.vV($Y,$CLJS.Al,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$Y],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.yL,RY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],
null)],null));SY=$CLJS.D(YY);TY=null;UY=0}VY=0}else break}
$CLJS.vV($CLJS.qK,$CLJS.Al,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.qK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.yL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xK,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.mB,$CLJS.oD,$CLJS.RI],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.hq,$CLJS.TL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oL],null)],null));$CLJS.vV($CLJS.nI,$CLJS.Al,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.nI],null),$CLJS.yL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.sL,$CLJS.uL],null)],null));
$CLJS.jL(Vma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.hY],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CV,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Gt,$CLJS.gm,$CLJS.CK,$CLJS.UI,$CLJS.JI,$CLJS.hm,$CLJS.jm,$CLJS.tm,$CLJS.sm,$CLJS.xI,$CLJS.nJ,$CLJS.KI,$CLJS.$I,$CLJS.KJ,$CLJS.vJ,$CLJS.uJ,$CLJS.mK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.TI,$CLJS.Ol],null)],null));