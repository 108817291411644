var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Dla,Ela,Fla,Gla,Hla,Ila,Jla,qW,Kla,Lla,Mla,Nla,Ola,Pla,Qla,Rla,sW,Sla;$CLJS.kW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);Dla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.lW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Ela=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Fla=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.mW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.nW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.oW=new $CLJS.N(null,"column-name","column-name",551523580);Gla=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.pW=new $CLJS.N(null,"display-info","display-info",-816930907);
Hla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);Ila=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Jla=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);qW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Kla=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Lla=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
Mla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Nla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);Ola=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Pla=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Qla=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.rW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Rla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
sW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);Sla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.AV($CLJS.kD,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.LL],null)],null)],null)],null)]));$CLJS.AV($CLJS.MJ,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.LL],null)],null)],null)],null)]));
$CLJS.yV($CLJS.CJ,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.yV($CLJS.MI,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.LL],null)],null)]));$CLJS.yV($CLJS.eK,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)],null)]));
$CLJS.yV($CLJS.Yl,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)],null)]));$CLJS.nV($CLJS.Yl,$CLJS.PL);$CLJS.yV($CLJS.uK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.uK,$CLJS.PL);$CLJS.yV($CLJS.qk,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.QL],null)],null)]));$CLJS.nV($CLJS.qk,$CLJS.PL);
$CLJS.jL(qW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.dv,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.yV($CLJS.mJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,qW],null)]));$CLJS.nV($CLJS.mJ,$CLJS.PL);
$CLJS.yV($CLJS.YJ,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)],null)]));$CLJS.yV($CLJS.zK,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.yV($CLJS.sK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));
$CLJS.yV($CLJS.QK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.sK,$CLJS.PL);$CLJS.nV($CLJS.QK,$CLJS.PL);$CLJS.yV($CLJS.NK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.KL],null)],null)]));$CLJS.nV($CLJS.NK,$CLJS.PL);
$CLJS.yV($CLJS.QI,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.jL(sW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Bk,Rla,Pla,Ila,Ela,Dla,Hla,Jla,Kla,Lla,Ola,Mla,Sla,Qla,Fla,Nla,$CLJS.Lb],null));$CLJS.jL($CLJS.lW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ml,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,sW],null)],null));
$CLJS.tW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.CV,$CLJS.kD,$CLJS.nW,!1,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Count of rows"),$CLJS.oW,$CLJS.VH("Count"),$CLJS.WG,$CLJS.VH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.sK,$CLJS.kW,$CLJS.Vl,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Sum of ..."),$CLJS.oW,$CLJS.VH("Sum"),$CLJS.WG,$CLJS.VH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.CJ,$CLJS.kW,$CLJS.Vl,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Average of ..."),$CLJS.oW,$CLJS.VH("Average"),$CLJS.WG,$CLJS.VH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.uK,$CLJS.kW,$CLJS.Vl,$CLJS.nW,!0,$CLJS.rW,$CLJS.EJ,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Median of ..."),$CLJS.oW,$CLJS.VH("Median"),$CLJS.WG,$CLJS.VH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.MI,$CLJS.kW,$CLJS.Vt,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Number of distinct values of ..."),$CLJS.oW,$CLJS.VH("Distinct values"),$CLJS.WG,$CLJS.VH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.QK,$CLJS.kW,$CLJS.Vl,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Cumulative sum of ..."),
$CLJS.oW,$CLJS.VH("Sum"),$CLJS.WG,$CLJS.VH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.CV,$CLJS.MJ,$CLJS.nW,!1,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Cumulative count of rows"),$CLJS.oW,$CLJS.VH("Count"),$CLJS.WG,$CLJS.VH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.zK,$CLJS.kW,$CLJS.Vl,$CLJS.nW,
!0,$CLJS.rW,$CLJS.kK,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Standard deviation of ..."),$CLJS.oW,$CLJS.VH("SD"),$CLJS.WG,$CLJS.VH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.qk,$CLJS.kW,$CLJS.Fk,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Minimum of ..."),$CLJS.oW,$CLJS.VH("Min"),$CLJS.WG,$CLJS.VH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.Yl,$CLJS.kW,$CLJS.Fk,$CLJS.nW,!0,$CLJS.rW,$CLJS.fI,$CLJS.pW,function(){return new $CLJS.k(null,3,[$CLJS.TI,$CLJS.VH("Maximum of ..."),$CLJS.oW,$CLJS.VH("Max"),$CLJS.WG,$CLJS.VH("Maximum value of a column")],null)}],null)],null);
$CLJS.jL(Gla,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.mW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CV,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Gt],null),$CLJS.rg.g($CLJS.CV),$CLJS.tW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.Ek],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nW,$CLJS.kk],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.rW,$CLJS.Ek],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pW,$CLJS.de],null)],null));