var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vW,wW,Tla,yW,Ula,Vla,Wla,zW,xW;$CLJS.uW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};vW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.OH(b,$CLJS.OI)?$CLJS.JV:$CLJS.OH(b,$CLJS.XK)?$CLJS.nL:$CLJS.OH(b,$CLJS.SJ)?$CLJS.oL:null;return $CLJS.m(b)?$CLJS.pV(b,a):!0};
wW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.OH($CLJS.DL(d),$CLJS.lJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.DL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(vW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Tla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,xW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,xW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.fv,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.ql);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(wW(b))].join("")}],null),$CLJS.$f(wW)],null)],null)};
yW=function(a){var b=$CLJS.T,c=Tla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.Bk,c,a],null)};Ula=function(a){return $CLJS.Ve($CLJS.mV,$CLJS.rg.h(function(b){var c=$CLJS.DL(b),d=$CLJS.OH(c,$CLJS.ML);b=d?$CLJS.pV($CLJS.DV,b):d;return $CLJS.m(b)?$CLJS.El:c},a))};Vla=function(a){a=$CLJS.uW(function(b){return!$CLJS.OH(b,$CLJS.lJ)},$CLJS.rg.h($CLJS.DL,a));return $CLJS.OH(a,$CLJS.ML)?$CLJS.Ll:a};Wla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.OH($CLJS.DL(b),$CLJS.lJ)},a))?Vla(a):Ula(a)};
zW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.AW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);xW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.jL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lm,xW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.DL(a);return $CLJS.Wf(function(d){return vW(d,c)},b)}],null)],null));
$CLJS.jL(zW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)],null));$CLJS.uV($CLJS.lm,yW($CLJS.lm));$CLJS.uV($CLJS.xu,yW($CLJS.xu));$CLJS.AV($CLJS.mm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,zW],null)]));$CLJS.AV($CLJS.PK,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,zW],null)]));
for(var BW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lm,$CLJS.xu,$CLJS.mm],null)),CW=null,DW=0,EW=0;;)if(EW<DW){var Xla=CW.$(null,EW);$CLJS.nV(Xla,$CLJS.AW);EW+=1}else{var FW=$CLJS.A(BW);if(FW){var GW=FW;if($CLJS.re(GW)){var HW=$CLJS.$c(GW),Yla=$CLJS.ad(GW),Zla=HW,$la=$CLJS.E(HW);BW=Yla;CW=Zla;DW=$la}else{var ama=$CLJS.C(GW);$CLJS.nV(ama,$CLJS.AW);BW=$CLJS.D(GW);CW=null;DW=0}EW=0}else break}$CLJS.CL.o(null,$CLJS.AW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Wla(a)});
$CLJS.yV($CLJS.tI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.tI,$CLJS.PL);
for(var IW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,$CLJS.ZH,$CLJS.oJ],null)),JW=null,KW=0,LW=0;;)if(LW<KW){var bma=JW.$(null,LW);$CLJS.yV(bma,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));LW+=1}else{var MW=$CLJS.A(IW);if(MW){var NW=MW;if($CLJS.re(NW)){var OW=$CLJS.$c(NW),cma=$CLJS.ad(NW),dma=OW,ema=$CLJS.E(OW);IW=cma;JW=dma;KW=ema}else{var fma=$CLJS.C(NW);$CLJS.yV(fma,$CLJS.G([$CLJS.xu,$CLJS.KK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));IW=$CLJS.D(NW);JW=null;KW=0}LW=0}else break}
for(var PW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jI,$CLJS.GJ,$CLJS.VJ],null)),QW=null,RW=0,SW=0;;)if(SW<RW){var gma=QW.$(null,SW);$CLJS.yV(gma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));SW+=1}else{var TW=$CLJS.A(PW);if(TW){var UW=TW;if($CLJS.re(UW)){var VW=$CLJS.$c(UW),hma=$CLJS.ad(UW),ima=VW,jma=$CLJS.E(VW);PW=hma;QW=ima;RW=jma}else{var kma=$CLJS.C(UW);$CLJS.yV(kma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));PW=$CLJS.D(UW);QW=null;RW=0}SW=0}else break}$CLJS.yV($CLJS.mI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.SL],null)],null)]));
$CLJS.CL.o(null,$CLJS.mI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.OH($CLJS.DL(b),$CLJS.il)&&$CLJS.OH($CLJS.DL(a),$CLJS.il)?$CLJS.il:$CLJS.KK});