var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ioa,$_,Joa,Koa,Moa,Loa;$CLJS.W_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};Ioa=function(a){var b=new $CLJS.rb,c=$CLJS.Rz;$CLJS.Rz=new $CLJS.gd(b);try{var d=$CLJS.Rz,e=$CLJS.Gb($CLJS.ZA(d)),f=$CLJS.Rz;$CLJS.Rz=e?$CLJS.QA(d):d;try{d=$CLJS.aB;$CLJS.aB=!0;try{$CLJS.cB(a)}finally{$CLJS.aB=d}$CLJS.F.h(0,$CLJS.Yz($CLJS.Rz,$CLJS.$z))||$CLJS.Qc($CLJS.Rz,"\n");$CLJS.Xz()}finally{$CLJS.Rz=f}$CLJS.Nz($CLJS.p.g(b))}finally{$CLJS.Rz=c}};
$CLJS.X_=function(a){var b=new $CLJS.rb,c=$CLJS.Ab;$CLJS.Ab=function(d){return b.append(d)};try{Ioa(a)}finally{$CLJS.Ab=c}return $CLJS.p.g(b)};$CLJS.Y_=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.Zd(a))&&$CLJS.Ie($CLJS.Zd(a),$CLJS.HG)};$CLJS.Z_=function(a){return $CLJS.Y_(a)?$CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.NL],null)):null};
$_=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Qm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Lj)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.RN(d,$CLJS.Pf([b,c])):d};Joa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$P,$CLJS.mR],null));b=a0.g?a0.g(b):a0.call(null,b);return $CLJS.fW($CLJS.U.l($_($CLJS.Dm.l(a,$CLJS.$P,$CLJS.G([$CLJS.mR])),$CLJS.bS,$CLJS.jZ),$CLJS.rl,$CLJS.bZ,$CLJS.G([$CLJS.gZ,b])))};
Koa=function(a){return $CLJS.Pm.h(Joa,a)};Moa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Qm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.b0,a],null):a,$CLJS.b0,function(b){return $CLJS.Pm.h(function(c){return $CLJS.U.j($CLJS.bG(c,$CLJS.nG),$CLJS.rl,$CLJS.hW)},b)}),$CLJS.rl,Loa):null};
$CLJS.c0=function(a){if($CLJS.F.h($CLJS.rl.g(a),$CLJS.LZ))return a;var b=$CLJS.Wk.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.fn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.rl,$CLJS.LZ,$CLJS.gZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.fn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.rl,$CLJS.HZ],null),$CLJS.RN($CLJS.VQ.g(a),new $CLJS.k(null,1,[$CLJS.wO,$CLJS.VQ],null))]))],null)],null),$CLJS.Dm.l(a,$CLJS.Wk,$CLJS.G([$CLJS.VQ]))]));case "query":return $CLJS.fn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.rl,$CLJS.LZ,$CLJS.gZ,a0($CLJS.wO.g(a))],null),$CLJS.Dm.l(a,$CLJS.Wk,$CLJS.G([$CLJS.wO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.d0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.qL(a)):null};$CLJS.e0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.f0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.g0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.h0=new $CLJS.N(null,"stage-number","stage-number",-1752729638);$CLJS.Noa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.i0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.j0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.b0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.Ooa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.k0=new $CLJS.N(null,"table","table",-564943036);
$CLJS.l0=new $CLJS.N("metadata","card","metadata/card",-1039333889);Loa=new $CLJS.N("metadata","results","metadata/results",330329298);var a0;
a0=function a0(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.mR),d=$CLJS.M.h(a,$CLJS.PP);c=$CLJS.m(c)?a0.g?a0.g(c):a0.call(null,c):$CLJS.Lg;d=Moa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Pu(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.nZ],null),d):c;d=$CLJS.m($CLJS.VQ.g(a))?$CLJS.HZ:$CLJS.BZ;a=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.rl,d],null),$CLJS.Dm.l(a,$CLJS.mR,$CLJS.G([$CLJS.PP]))]));a=$CLJS.A($CLJS.WO.g(a))?$CLJS.Qm.j(a,$CLJS.WO,Koa):a;a=$_(a,$CLJS.KO,$CLJS.MZ);return $CLJS.kf.h(c,a)};
$CLJS.m0=RegExp(" id$","i");