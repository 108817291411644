var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var Poa,p0,q0,r0,o0,s0,Qoa,Roa,Soa,w0,x0,z0,A0,B0,C0,D0,E0,n0,Uoa,Voa,H0,Woa,I0,Xoa,K0,Yoa,Zoa,$oa,apa,bpa;Poa=function(a){var b=n0;return $CLJS.ZF(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};p0=function(a){var b=o0;return $CLJS.ZF(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
q0=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.nk,a],null));};r0=function(a){var b=$CLJS.ne(a);return b?(b=!$CLJS.Ig(a))?$CLJS.Nm.h($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b};o0=function(a){return $CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a))?$CLJS.H_(a):a};s0=function(a,b){var c=r0(b);return $CLJS.m(c)?(b=o0($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c};
Qoa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.Pj,$CLJS.Ye,$CLJS.Wk,o0,$CLJS.cJ,o0],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=t0.h?t0.h(b,u0):t0.call(null,b,u0);return d.call(c,b,a)})};
Roa=function(a){a=$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=o0(c);var d=Qoa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.cI)&&$CLJS.Gb($CLJS.cJ.g(a))?$CLJS.U.j(a,$CLJS.cJ,$CLJS.FR):a};
Soa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.e_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(Roa(b),$CLJS.V,c)],null)}),a)};w0=function(a){return $CLJS.v0.j($CLJS.v0.j($CLJS.Sb(function(b,c){return $CLJS.v0.j(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.tJ,$CLJS.ega,$CLJS.DP,Toa,$CLJS.rF,$CLJS.Wj],null)),$CLJS.JG,$CLJS.Lm.h(n0,t0)),$CLJS.fP,$CLJS.XZ)};
x0=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,a,null],null):a};z0=function(a){return y0.g(x0(a))};A0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.g_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(y0,b)))};B0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,z0(b)],null),$CLJS.rg.h(y0,c))};
C0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,z0(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)};D0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,z0(a)],null)};E0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,y0.g(a)],null)};
n0=function(a){return F0(function(b){if($CLJS.oe(b))return Poa(b);if($CLJS.Gb(r0(b)))return b;try{return y0.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.XF($CLJS.Wu);if($CLJS.m($CLJS.dG("metabase.mbql.normalize",d))){var e=$CLJS.VH("Invalid clause:");e instanceof Error?$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([b])),e):$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([e,b])),null)}throw $CLJS.yj($CLJS.UH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.SZ(c)])),new $CLJS.k(null,
1,[$CLJS.kS,b],null),c);}throw f;}},a)};Uoa=function(a){return $CLJS.Mg($CLJS.eg(n0,G0(a)))};Voa=function(a){for(;;)if($CLJS.m(r0(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Pm.h(x0,a))};H0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.cI);return $CLJS.m(b)?$CLJS.Qm.j(a,$CLJS.cI,y0):a};
Woa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,H0(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[l,H0(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};I0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.jR);return $CLJS.m(b)?$CLJS.Qm.j(a,$CLJS.jR,Woa):a};Xoa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.VQ);b=$CLJS.Gb(a)?J0.g?J0.g(b):J0.call(null,b):b;return $CLJS.m(a)?I0(b):b};K0=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a};
Yoa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.wO);var c=$CLJS.M.h(b,$CLJS.PO),d=$CLJS.M.h(b,$CLJS.PP),e=$CLJS.M.h(b,$CLJS.VQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.PP);var l=$CLJS.Pu($CLJS.Dm.h(f,$CLJS.PP),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wO,$CLJS.PP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Qm.j(l,$CLJS.wO,J0):l,q=$CLJS.m(c)?$CLJS.Qm.j(n,$CLJS.PO,$CLJS.bg($CLJS.Pm,n0)):n,u=$CLJS.m(e)?$CLJS.Qm.j(q,$CLJS.VQ,I0):q;return n0(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.yj($CLJS.UH("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.SZ(n)])),new $CLJS.k(null,1,[$CLJS.wO,a],null),n);throw v;}};
Zoa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.wO),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.HP);c=$CLJS.M.h(c,$CLJS.yO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.si($CLJS.Ve($CLJS.qg,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.FQ)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,n,$CLJS.Dm.h(q,$CLJS.RQ)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.rZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b))))),$CLJS.L0.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wO,$CLJS.yO],null),$CLJS.Lm.h($CLJS.Mg,$CLJS.bg($CLJS.Om,b)))):a};
$oa=function(a){try{return Zoa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.VH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.wO,a],null),b);}throw c;}};
apa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=M0.h?M0.h(B,I):M0.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return M0.h?M0.h(y,B):M0.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null};bpa=function(a,b){a=$CLJS.Pm.h(function(c){var d=$CLJS.kf.h(b,N0);return M0.h?M0.h(c,d):M0.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null};
$CLJS.L0=function L0(a){switch(arguments.length){case 3:return L0.j(arguments[0],arguments[1],arguments[2]);case 4:return L0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return L0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return L0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return L0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.L0.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.C(d);if(d=$CLJS.D(d))a=$CLJS.U.j(a,b,$CLJS.L0.j($CLJS.M.h(a,b),d,c));else{d=$CLJS.U.j;var e=$CLJS.M.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.U,a,b,c)}return a};
$CLJS.L0.D=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.C(e);if(e=$CLJS.D(e))a=$CLJS.U.j(a,b,$CLJS.L0.D($CLJS.M.h(a,b),e,c,d));else{e=$CLJS.U.j;var f=$CLJS.M.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.L0.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.C(f);if(f=$CLJS.D(f))a=$CLJS.U.j(a,b,$CLJS.L0.R($CLJS.M.h(a,b),f,c,d,e));else{f=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.L0.aa=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.C(g);if(g=$CLJS.D(g))a=$CLJS.U.j(a,b,$CLJS.L0.aa($CLJS.M.h(a,b),g,c,d,e,f));else{g=$CLJS.U.j;var l=$CLJS.M.h(a,b);c=c.D?c.D(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.L0.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.C(l);return(l=$CLJS.D(l))?$CLJS.U.j(a,b,$CLJS.R.l($CLJS.L0,$CLJS.M.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.M.h(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.L0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.L0.v=6;
$CLJS.v0=function v0(a){switch(arguments.length){case 3:return v0.j(arguments[0],arguments[1],arguments[2]);case 4:return v0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return v0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return v0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return v0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.v0.j=function(a,b,c){var d=$CLJS.Ke(a,b);if($CLJS.m(d)){var e=$CLJS.U.j;d=$CLJS.uc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.v0.D=function(a,b,c,d){var e=$CLJS.Ke(a,b);if($CLJS.m(e)){var f=$CLJS.U.j;e=$CLJS.uc(e);c=c.h?c.h(e,d):c.call(null,e,d);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.v0.R=function(a,b,c,d,e){var f=$CLJS.Ke(a,b);if($CLJS.m(f)){var g=$CLJS.U.j;f=$CLJS.uc(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.v0.aa=function(a,b,c,d,e,f){var g=$CLJS.Ke(a,b);if($CLJS.m(g)){var l=$CLJS.U.j;g=$CLJS.uc(g);c=c.D?c.D(g,d,e,f):c.call(null,g,d,e,f);a=l.call($CLJS.U,a,b,c)}return a};$CLJS.v0.l=function(a,b,c,d,e,f,g){var l=$CLJS.Ke(a,b);return $CLJS.m(l)?$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.uc(l),d,e,f,$CLJS.G([g]))):a};
$CLJS.v0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.v0.v=6;
var F0=function F0(a,b){return $CLJS.VZ($CLJS.bg(F0,a),$CLJS.Ye,a.g?a.g(b):a.call(null,b))},cpa=new $CLJS.N(null,"value_field","value_field",-980977878),O0=new $CLJS.N(null,"ascending","ascending",-988350486),P0=new $CLJS.N(null,"named","named",-422393479),Q0=new $CLJS.N(null,"values_source_config","values_source_config",-590570309),dpa=new $CLJS.N(null,"field-id","field-id",-353751335),R0=new $CLJS.N(null,"descending","descending",-24766135),S0=new $CLJS.N(null,"binning-strategy","binning-strategy",
2063329158),T0=new $CLJS.N(null,"datetime-field","datetime-field",21731696),epa=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),U0=new $CLJS.N(null,"field-literal","field-literal",-1295883554),V0=new $CLJS.N(null,"viz-settings","viz-settings",256055379),fpa=new $CLJS.N(null,"rows","rows",850049680),gpa=new $CLJS.N(null,"special-fn","special-fn",1290649344),N0=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),u0=new $CLJS.N(null,
"ignore-path","ignore-path",944069061),hpa=new $CLJS.N(null,"label_field","label_field",-1573182765),ipa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),jpa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),Toa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var W0,kpa=$CLJS.cj.g($CLJS.P),lpa=$CLJS.cj.g($CLJS.P),mpa=$CLJS.cj.g($CLJS.P),npa=$CLJS.cj.g($CLJS.P),opa=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));W0=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Lm.h(o0,$CLJS.C),$CLJS.Pj,opa,kpa,lpa,mpa,npa);W0.o(null,$CLJS.OP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OP,b],null);return null!=a?$CLJS.kf.h(b,a):b});
W0.o(null,$CLJS.zG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,a instanceof $CLJS.N?$CLJS.e_(a):a],null)});W0.o(null,S0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(W0.g(new $CLJS.S(null,3,5,$CLJS.T,[S0,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[S0,t0.h?t0.h(b,u0):t0.call(null,b,u0),o0(c)],null)});
W0.o(null,$CLJS.FQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=t0.h?t0.h(a,u0):t0.call(null,a,u0);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,b,function(){var d=$CLJS.m($CLJS.CG.g(c))?$CLJS.Qm.j(c,$CLJS.CG,$CLJS.Oi):c;d=$CLJS.m($CLJS.RQ.g(c))?$CLJS.Qm.j(d,$CLJS.RQ,$CLJS.Oi):d;return $CLJS.m($CLJS.QR.g(c))?$CLJS.Qm.j(d,$CLJS.QR,function(e){return $CLJS.m($CLJS.WK.g(e))?$CLJS.Qm.j(e,$CLJS.WK,$CLJS.Oi):e}):d}()],null)});
W0.o(null,U0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[U0,b instanceof $CLJS.N?$CLJS.e_(b):b,$CLJS.Oi.g(a)],null)});W0.o(null,T0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[T0,t0.h?t0.h(b,u0):t0.call(null,b,u0),$CLJS.wu,o0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[T0,t0.h?t0.h(b,u0):t0.call(null,b,u0),o0(c)],null)});
W0.o(null,$CLJS.qK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(W0.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qK,b,c,d],null)),t0.h?t0.h(a,u0):t0.call(null,a,u0)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qK,t0.h?t0.h(b,u0):t0.call(null,b,u0),$CLJS.Ae(c)?c:o0(c),o0(d)],null)});
W0.o(null,$CLJS.rJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rJ,b,o0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,$CLJS.mB],null)});W0.o(null,$CLJS.iJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iJ,b,o0(a)],null)});
W0.o(null,$CLJS.dK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.dK,t0.h?t0.h(b,u0):t0.call(null,b,u0),c,o0(a)],null)});W0.o(null,$CLJS.LK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.LK,t0.h?t0.h(b,u0):t0.call(null,b,u0),c,o0(a)],null)});
W0.o(null,$CLJS.cK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cK,t0.h?t0.h(b,u0):t0.call(null,b,u0),o0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cK,t0.h?t0.h(b,u0):t0.call(null,b,u0)],null)});
W0.o(null,$CLJS.oK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.oK,t0.h?t0.h(b,u0):t0.call(null,b,u0),o0(c),o0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oK,t0.h?t0.h(b,u0):t0.call(null,b,u0),o0(c)],null)});
W0.o(null,$CLJS.hJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.hJ,t0.h?t0.h(b,u0):t0.call(null,b,u0),t0.h?t0.h(c,u0):t0.call(null,c,u0),o0(a)],null)});W0.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,b,a],null)});
W0.o(null,$CLJS.Pj,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[o0(a)],null),$CLJS.rg.g(function(c){return t0.h?t0.h(c,u0):t0.call(null,c,u0)}),b)});
var X0=function X0(a){if($CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a))){var c=o0(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.qk,null,$CLJS.mm,null,$CLJS.zK,null,$CLJS.eK,null,$CLJS.xu,null,$CLJS.MJ,null,$CLJS.NK,null,$CLJS.PK,null,$CLJS.MI,null,$CLJS.mJ,null,$CLJS.lm,null,$CLJS.QI,null,$CLJS.uK,null,$CLJS.YJ,null,$CLJS.Yl,null,$CLJS.kD,null,$CLJS.CJ,null,$CLJS.sK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(r0(a))?(a=$CLJS.C(a),X0.g?X0.g(a):X0.call(null,
a)):null},ppa=new $CLJS.k(null,8,[$CLJS.Wk,o0,$CLJS.VQ,function(a){a=p0(a);return $CLJS.A($CLJS.jR.g(a))?$CLJS.Qm.j(a,$CLJS.jR,Soa):a},$CLJS.wO,new $CLJS.k(null,6,[$CLJS.OP,function Y0(a){if($CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a)))return o0(a);if($CLJS.m(s0(P0,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[P0,Y0.g?Y0.g(a):Y0.call(null,a)],null),c)}return $CLJS.m($CLJS.m(r0(a))?X0($CLJS.Zd(a)):null)?$CLJS.Pm.h(Y0,a):t0.h?t0.h(a,
u0):t0.call(null,a,u0)},$CLJS.QQ,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(u),t0.h?t0.h(q,u0):t0.call(null,q,u0)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(l),t0.h?t0.h(f,u0):t0.call(null,f,u0)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.$R,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(r0(q))?W0.g(q):$CLJS.lf(W0.g($CLJS.lf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(r0(l))?W0.g(l):$CLJS.lf(W0.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.mR,function(a){a=p0(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.VQ);if($CLJS.m(b))return a=$CLJS.RN(a,new $CLJS.k(null,1,[$CLJS.VQ,$CLJS.wO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.VQ],null),a=t0.h?t0.h(a,b):t0.call(null,a,b),$CLJS.RN(a,new $CLJS.k(null,1,[$CLJS.wO,$CLJS.VQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null);return t0.h?t0.h(a,b):t0.call(null,a,b)},$CLJS.PP,new $CLJS.k(null,1,[N0,w0],null),$CLJS.WO,new $CLJS.k(null,1,[N0,function(a){a=t0.h?t0.h(a,$CLJS.wO):t0.call(null,a,$CLJS.wO);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.WK),d=$CLJS.M.h(b,$CLJS.yO);a=$CLJS.M.h(b,$CLJS.xP);b=$CLJS.m(c)?$CLJS.Qm.j(b,$CLJS.WK,o0):b;d=$CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Qm.j(b,$CLJS.yO,o0):b;return $CLJS.m(a)?$CLJS.Qm.j(d,$CLJS.xP,$CLJS.e_):d}],null)],null),
$CLJS.ZD,new $CLJS.k(null,1,[$CLJS.pP,$CLJS.Ye],null),$CLJS.PO,new $CLJS.k(null,1,[N0,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Wk),d=$CLJS.M.h(b,$CLJS.$K),e=$CLJS.M.h(b,$CLJS.Gk);a=$CLJS.M.h(b,Q0);b=$CLJS.m(e)?$CLJS.Qm.j(b,$CLJS.Gk,$CLJS.e_):b;c=$CLJS.m(c)?$CLJS.Qm.j(b,$CLJS.Wk,o0):b;d=$CLJS.m(d)?$CLJS.Qm.j(c,$CLJS.$K,function(f){return t0.h?t0.h(f,u0):t0.call(null,f,u0)}):c;d=$CLJS.m(a)?$CLJS.L0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[Q0,hpa],null),function(f){return t0.h?t0.h(f,u0):t0.call(null,
f,u0)}):d;return $CLJS.m(a)?$CLJS.L0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[Q0,cpa],null),function(f){return t0.h?t0.h(f,u0):t0.call(null,f,u0)}):d}],null),$CLJS.NO,function(a){return null==a?null:o0(a)},$CLJS.PP,new $CLJS.k(null,1,[N0,w0],null),V0,o0],null),t0=function t0(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return t0.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
t0.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Ju(ppa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=o0(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,t0.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=o0(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,t0.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(r0(a))?W0.g(a):$CLJS.ne(a)?$CLJS.Pm.h(function(e){return t0.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),N0)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.UH("Error normalizing form: {0}",$CLJS.G([$CLJS.SZ(d)])),new $CLJS.k(null,3,[$CLJS.qF,a,$CLJS.$l,c,gpa,b],null),d);}throw e;}};t0.v=1;t0.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var y0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(r0(f))?$CLJS.C(f):null},$CLJS.Pj,e,a,b,c,d)}();y0.o(null,$CLJS.Pj,function(a){return a});
y0.o(null,$CLJS.FQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(s0($CLJS.FQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return y0.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,c,$CLJS.Rf($CLJS.fn.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,b,$CLJS.Rf(a)],null)});
y0.o(null,$CLJS.OP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OP,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OP,b,a],null)});y0.o(null,dpa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(r0(a))?y0.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,a,null],null)});
y0.o(null,U0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,b,new $CLJS.k(null,1,[$CLJS.CG,a],null)],null)});y0.o(null,ipa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=z0(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=z0(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,a,$CLJS.U.j(c,$CLJS.XO,b)],null)});
y0.o(null,jpa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=z0(a);return $CLJS.R.D($CLJS.r_,a,$CLJS.U,$CLJS.G([$CLJS.nQ,b]))});
y0.o(null,T0,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=z0(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.CG);return $CLJS.Gb(c)||$CLJS.IS.h(c,b)?$CLJS.R.D($CLJS.r_,a,$CLJS.U,$CLJS.G([$CLJS.RQ,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),y0.g(new $CLJS.S(null,3,5,$CLJS.T,[T0,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});y0.o(null,S0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=z0(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,b,$CLJS.U.j(e,$CLJS.QR,$CLJS.fn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.WK,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var Z0=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk,$CLJS.Tk],null)),$0=null,a1=0,b1=0;;)if(b1<a1){var c1=$0.$(null,b1);y0.o(null,c1,function(){return function(a){return A0(a)}}(Z0,$0,a1,b1,c1));b1+=1}else{var d1=$CLJS.A(Z0);if(d1){var e1=d1;if($CLJS.re(e1)){var f1=$CLJS.$c(e1),qpa=$CLJS.ad(e1),rpa=f1,spa=$CLJS.E(f1);Z0=qpa;$0=rpa;a1=spa}else{var g1=$CLJS.C(e1);y0.o(null,g1,function(){return function(a){return A0(a)}}(Z0,$0,a1,b1,g1,e1,d1));Z0=$CLJS.D(e1);$0=null;a1=0}b1=0}else break}
y0.o(null,$CLJS.UI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UI,z0(a),z0(b)],null),c)});y0.o(null,$CLJS.qK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=z0(a);a=$CLJS.m($CLJS.f_($CLJS.FQ,a))?$CLJS.r_.l(c,$CLJS.Dm,$CLJS.G([$CLJS.RQ])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qK,a],null),b)});
for(var h1=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.uJ,$CLJS.mK,$CLJS.KJ,$CLJS.vJ,$CLJS.gm,$CLJS.CK,$CLJS.hm,$CLJS.tm,$CLJS.jm,$CLJS.sm,$CLJS.KI,$CLJS.$I,$CLJS.xI,$CLJS.nJ,$CLJS.JI],null)),i1=null,j1=0,k1=0;;)if(k1<j1){var l1=i1.$(null,k1);y0.o(null,l1,function(){return function(a){return B0(a)}}(h1,i1,j1,k1,l1));k1+=1}else{var m1=$CLJS.A(h1);if(m1){var n1=m1;if($CLJS.re(n1)){var o1=$CLJS.$c(n1),tpa=$CLJS.ad(n1),upa=o1,vpa=$CLJS.E(o1);h1=tpa;i1=upa;j1=vpa}else{var p1=$CLJS.C(n1);y0.o(null,p1,
function(){return function(a){return B0(a)}}(h1,i1,j1,k1,p1,n1,m1));h1=$CLJS.D(n1);i1=null;j1=0}k1=0}else break}y0.o(null,fpa,function(){return null});y0.o(null,$CLJS.pO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pO,y0.g(b),a],null)});
y0.o(null,P0,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=y0.g;var e=$CLJS.T;b=y0.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,epa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.TI,a],null);return c.call(y0,new $CLJS.S(null,3,5,e,[$CLJS.pO,b,a],null))});
for(var q1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kD,$CLJS.MJ],null)),r1=null,s1=0,t1=0;;)if(t1<s1){var u1=r1.$(null,t1);y0.o(null,u1,function(){return function(a){return C0(a)}}(q1,r1,s1,t1,u1));t1+=1}else{var v1=$CLJS.A(q1);if(v1){var w1=v1;if($CLJS.re(w1)){var x1=$CLJS.$c(w1),wpa=$CLJS.ad(w1),xpa=x1,ypa=$CLJS.E(x1);q1=wpa;r1=xpa;s1=ypa}else{var y1=$CLJS.C(w1);y0.o(null,y1,function(){return function(a){return C0(a)}}(q1,r1,s1,t1,y1,w1,v1));q1=$CLJS.D(w1);r1=null;s1=0}t1=0}else break}
for(var z1=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.CJ,$CLJS.QK,$CLJS.MI,$CLJS.zK,$CLJS.sK,$CLJS.qk,$CLJS.Yl,$CLJS.uK,$CLJS.QI],null)),A1=null,B1=0,C1=0;;)if(C1<B1){var D1=A1.$(null,C1);y0.o(null,D1,function(){return function(a){return D0(a)}}(z1,A1,B1,C1,D1));C1+=1}else{var E1=$CLJS.A(z1);if(E1){var F1=E1;if($CLJS.re(F1)){var G1=$CLJS.$c(F1),zpa=$CLJS.ad(F1),Apa=G1,Bpa=$CLJS.E(G1);z1=zpa;A1=Apa;B1=Bpa}else{var H1=$CLJS.C(F1);y0.o(null,H1,function(){return function(a){return D0(a)}}(z1,A1,B1,C1,
H1,F1,E1));z1=$CLJS.D(F1);A1=null;B1=0}C1=0}else break}y0.o(null,$CLJS.mJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mJ,z0(b),a],null)});
for(var I1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YJ,$CLJS.eK],null)),J1=null,K1=0,L1=0;;)if(L1<K1){var M1=J1.$(null,L1);y0.o(null,M1,function(){return function(a){return E0(a)}}(I1,J1,K1,L1,M1));L1+=1}else{var N1=$CLJS.A(I1);if(N1){var O1=N1;if($CLJS.re(O1)){var P1=$CLJS.$c(O1),Cpa=$CLJS.ad(O1),Dpa=P1,Epa=$CLJS.E(P1);I1=Cpa;J1=Dpa;K1=Epa}else{var Q1=$CLJS.C(O1);y0.o(null,Q1,function(){return function(a){return E0(a)}}(I1,J1,K1,L1,Q1,O1,N1));I1=$CLJS.D(O1);J1=null;K1=0}L1=0}else break}
y0.o(null,$CLJS.NK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NK,y0.g(b),y0.g(a)],null)});
y0.o(null,$CLJS.ZI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(y0.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZI,b],null)),t0.l(a,$CLJS.G([u0]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZI,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[y0.g(v),y0.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[y0.g(n),y0.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
y0.o(null,$CLJS.aI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aI,y0.g(a),$CLJS.F.h(0,b)?1:y0.g(b)],null),$CLJS.rg.h(y0,c))});
var G0=function G0(a){return function f(d,e){try{if($CLJS.m(function(){var x=q0($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=q0($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Vm.j(e,
0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.mm,null,$CLJS.xu,null,$CLJS.PK,null,$CLJS.lm,null,P0,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(X0(n)))return $CLJS.Mg($CLJS.Ve($CLJS.qg,$CLJS.rg.h(G0,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;
throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Vm.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},R1=function R1(a){return function f(d,e){try{var g=q0($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,
1);if($CLJS.O(n,$CLJS.FJ)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.sJ))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,O0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof
Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,R0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,O0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,R0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,q],null));
throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.FJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FJ,z0(q)],null);throw $CLJS.Z;}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.sJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,z0(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;
}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Vm.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.Vm.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.hn.g($CLJS.rg.h(R1,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.d_(f,d,e);throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},J0=$CLJS.Lm.h(n0,function(a){var b=K0($CLJS.OP.g(a))?$CLJS.Qm.j(a,$CLJS.OP,Uoa):a;b=K0($CLJS.HP.g(a))?$CLJS.Qm.j(b,$CLJS.HP,Voa):b;b=K0($CLJS.yO.g(a))?$CLJS.Qm.j(b,$CLJS.yO,$CLJS.bg($CLJS.Pm,x0)):b;b=K0($CLJS.$R.g(a))?
$CLJS.Qm.j(b,$CLJS.$R,R1):b;return K0($CLJS.mR.g(a))?$CLJS.Qm.j(b,$CLJS.mR,Xoa):b}),Fpa=new $CLJS.k(null,3,[$CLJS.VQ,$CLJS.Ye,$CLJS.wO,new $CLJS.k(null,2,[$CLJS.mR,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.VQ);if($CLJS.m(b))return a=$CLJS.RN(a,new $CLJS.k(null,1,[$CLJS.VQ,$CLJS.wO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.VQ],null),a=M0.h?M0.h(a,b):M0.call(null,a,b),$CLJS.RN(a,new $CLJS.k(null,1,[$CLJS.wO,$CLJS.VQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null);return M0.h?M0.h(a,
b):M0.call(null,a,b)},$CLJS.WO,new $CLJS.k(null,1,[N0,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wO],null);return M0.h?M0.h(a,b):M0.call(null,a,b)}],null)],null),V0,$CLJS.Ye],null),M0=function M0(a){switch(arguments.length){case 1:return M0.g(arguments[0]);case 2:return M0.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};M0.g=function(a){return M0.h(a,$CLJS.Lg)};
M0.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Ju(Fpa,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?apa(a,b):$CLJS.ne(a)?bpa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.qF,a,$CLJS.$l,b],null),d);throw d;}};M0.v=2;
$CLJS.S1=function(){var a=$CLJS.Lm.l(M0,$oa,Yoa,$CLJS.G([t0]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.UH("Error normalizing query: {0}",$CLJS.G([$CLJS.SZ(c)])),new $CLJS.k(null,1,[$CLJS.wO,b],null),c);}throw d;}}}();