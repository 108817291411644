var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var DX,EX,FX,HX,IX,JX,KX;DX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);EX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
FX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.GX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);HX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
IX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);JX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);KX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.yV($CLJS.iJ,$CLJS.G([$CLJS.xu,$CLJS.lJ,$CLJS.Rt,$CLJS.oL]));$CLJS.CL.o(null,$CLJS.GX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.DL(a);return $CLJS.le(a)?(a=$CLJS.Ez.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.SJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.nV($CLJS.GX,$CLJS.PL);
for(var LX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dK,$CLJS.LK],null)),MX=null,NX=0,OX=0;;)if(OX<NX){var PX=MX.$(null,OX);$CLJS.yV(PX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oL],null)]));$CLJS.nV(PX,$CLJS.GX);OX+=1}else{var QX=$CLJS.A(LX);if(QX){var RX=QX;if($CLJS.re(RX)){var SX=$CLJS.$c(RX),Ima=$CLJS.ad(RX),Jma=SX,Kma=$CLJS.E(SX);LX=Ima;MX=Jma;NX=Kma}else{var TX=$CLJS.C(RX);$CLJS.yV(TX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.hq,$CLJS.JL],null),$CLJS.Rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oL],null)]));$CLJS.nV(TX,$CLJS.GX);LX=$CLJS.D(RX);MX=null;NX=0}OX=0}else break}
for(var UX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.EI,$CLJS.YH,$CLJS.aK,$CLJS.wK,$CLJS.gK,$CLJS.DI,$CLJS.SK],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var Lma=VX.$(null,XX);$CLJS.yV(Lma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)]));XX+=1}else{var YX=$CLJS.A(UX);if(YX){var ZX=YX;if($CLJS.re(ZX)){var $X=$CLJS.$c(ZX),Mma=$CLJS.ad(ZX),Nma=$X,Oma=$CLJS.E($X);UX=Mma;VX=Nma;WX=Oma}else{var Pma=$CLJS.C(ZX);$CLJS.yV(Pma,$CLJS.G([$CLJS.xu,
$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)]));UX=$CLJS.D(ZX);VX=null;WX=0}XX=0}else break}$CLJS.yV($CLJS.hJ,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.FV],null)]));
for(var aY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.SK,null,$CLJS.gK,null,$CLJS.wK,null,$CLJS.jK,null,$CLJS.DI,null,$CLJS.YH,null,$CLJS.EI,null,$CLJS.aK,null],null),null)),bY=null,cY=0,dY=0;;)if(dY<cY){var Qma=bY.$(null,dY);$CLJS.yV(Qma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)]));dY+=1}else{var eY=$CLJS.A(aY);if(eY){var fY=eY;if($CLJS.re(fY)){var gY=$CLJS.$c(fY),Rma=$CLJS.ad(fY),Sma=gY,Tma=$CLJS.E(gY);
aY=Rma;bY=Sma;cY=Tma}else{var Uma=$CLJS.C(fY);$CLJS.yV(Uma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)]));aY=$CLJS.D(fY);bY=null;cY=0}dY=0}else break}$CLJS.jL(KX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.AV($CLJS.cK,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,KX],null)],null)],null)],null)]));
$CLJS.jL(JX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.uL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,2,[$CLJS.dv,"valid timezone ID",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.VL.tz.names()))],null));
$CLJS.AV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$K,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,JX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,JX],null)],null)],null)],null)]));
$CLJS.nV($CLJS.uI,$CLJS.GX);$CLJS.yV($CLJS.DJ,$CLJS.G([$CLJS.xu,$CLJS.WI]));$CLJS.jL(FX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.zL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.OH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OI,$CLJS.SJ],null))}],null)],null));
$CLJS.jL(DX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,FX],null)],null)],null)],null));
$CLJS.uV($CLJS.FI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.dv,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.FI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,DX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zt,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.tX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.vX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.GV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.dv,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.mB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.xX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.EV],null)],null)],null)],null)],null)],null));
$CLJS.CL.o(null,$CLJS.FI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.mB)?$CLJS.F.h(a,$CLJS.Pj)?$CLJS.SJ:$CLJS.m($CLJS.pV($CLJS.GV,a))?$CLJS.OI:$CLJS.SJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.BX,b))?$CLJS.OI:$CLJS.m($CLJS.Di($CLJS.CX,b))?$CLJS.OI:null:null;if($CLJS.m(a))return a;b=$CLJS.DL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.OH(c,$CLJS.Ll)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.jL(IX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,$CLJS.mB],null),$CLJS.Rt],null));
$CLJS.AV($CLJS.rJ,$CLJS.G([$CLJS.xu,$CLJS.SJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,IX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.oL],null)],null)],null)],null)]));
$CLJS.yV($CLJS.YD,$CLJS.G([$CLJS.xu,$CLJS.XK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.RL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.nL],null)]));$CLJS.jL(EX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Gt,$CLJS.fJ,$CLJS.gk,$CLJS.am,$CLJS.XI,$CLJS.UK,$CLJS.ZK,$CLJS.ul,$CLJS.mk,$CLJS.bk,$CLJS.Rk,$CLJS.II],null));$CLJS.jL(HX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.AV($CLJS.oK,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,EX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,HX],null)],null)],
null)],null)]));