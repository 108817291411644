var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Vsa,t7,u7,Wsa,Xsa,Ysa,Zsa,z7,A7,C7,D7,G7,H7,$sa,x7,ata;$CLJS.o7=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.gZ);b=$CLJS.d3(a,b);return 0<b?b-1:null};$CLJS.p7=function(a,b,c,d){d=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.F3,$CLJS.a2(),$CLJS.D3,!0,$CLJS.J3,!0,$CLJS.B3,!0],null),d]));return $CLJS.R3.D(a,b,c,d)};$CLJS.q7=function(a,b,c){return $CLJS.p7(a,b,c,null)};Vsa=function(a,b){b=$CLJS.k3(a,b);return $CLJS.m(b)?$CLJS.f4(a,b):null};
$CLJS.r7=function(a){var b=$CLJS.CH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "field":return $CLJS.nQ.g($CLJS.EH(a));case "metadata/column":return $CLJS.k4.g(a);case "mbql/join":return $CLJS.xP.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.s7=function(a){return $CLJS.S6.g(a)};t7=function(a,b){return $CLJS.F.h($CLJS.i6.g(a),$CLJS.li(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WK,$CLJS.CI,$CLJS.TK],null)))};
u7=function(a){var b=new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.pL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.lk,$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zG,b,$CLJS.V.g(a)],null)};Wsa=function(a){return $CLJS.U3(a,new $CLJS.k(null,1,[$CLJS.WK,$CLJS.Oi],null))};
Xsa=function(a,b,c){var d=$CLJS.fn.l;b=$CLJS.Gb($CLJS.o7(a,b))?function(){var f=$CLJS.wZ.g($CLJS.C($CLJS.gZ.g(a)));return $CLJS.m(f)?(f=Vsa(a,f),$CLJS.m(f)?$CLJS.uW(function(g){return $CLJS.F.h($CLJS.Gk.g(g),c)},f):null):null}():null;try{var e=$CLJS.b4(a,c)}catch(f){e=null}return d.call($CLJS.fn,$CLJS.G([b,e]))};
Ysa=function(a,b){var c=$CLJS.uW(function(d){return $CLJS.F.h($CLJS.V2.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.uW(function(d){return $CLJS.F.h($CLJS.V.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.XF($CLJS.XD);$CLJS.m($CLJS.dG("metabase.lib.field",c))&&(b=$CLJS.UH("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([a])),$CLJS.aj.l($CLJS.G([$CLJS.Pm.h($CLJS.V2,b)]))])),b instanceof Error?$CLJS.eG("metabase.lib.field",c,$CLJS.UB(),b):$CLJS.eG("metabase.lib.field",
c,$CLJS.UB.l($CLJS.G([b])),null));return null};
Zsa=function(a,b,c){if($CLJS.m(v7))return null;var d=v7;v7=!0;try{var e=$CLJS.o7(a,b),f=$CLJS.m(e)?$CLJS.e3(a,e):$CLJS.e3(a,b),g=function(){var n=$CLJS.w7.g(f);if($CLJS.m(n))return n;n=$CLJS.Ju(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nZ,$CLJS.b0],null));if($CLJS.m(n))return n;n=$CLJS.m($CLJS.wZ.g(f))?$CLJS.q7(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.field",n))){var q=$CLJS.UH("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))]));
return q instanceof Error?$CLJS.eG("metabase.lib.field",n,$CLJS.UB(),q):$CLJS.eG("metabase.lib.field",n,$CLJS.UB.l($CLJS.G([q])),null)}return null}(),l=function(){var n=$CLJS.A(g);return n?Ysa(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.U.j($CLJS.U.j($CLJS.G5($CLJS.Dm.l(l,$CLJS.Gk,$CLJS.G([$CLJS.j0,x7,$CLJS.y7])),null),$CLJS.V,function(){var n=$CLJS.V2.g(l);return $CLJS.m(n)?n:$CLJS.V.g(l)}()),$CLJS.e0,$CLJS.Q2):l:null}finally{v7=d}};
z7=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.nQ);var f=$CLJS.J(c,2,null);c=$CLJS.fn.l($CLJS.G([function(){var g=$CLJS.CG.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null}(),function(){var g=$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.lk,g],null):null}(),function(){var g=$CLJS.QR.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[x7,g],null):null}(),function(){var g=$CLJS.RQ.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.y7,g],null):null}(),$CLJS.Ae(f)?function(){var g=Xsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null):function(){var g=Zsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.V,f],null)}()]));return $CLJS.m(d)?$CLJS.G5(c,d):c};A7=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.y7);return $CLJS.m($CLJS.m(b)?$CLJS.Ie($CLJS.VV,b):b)?$CLJS.il:$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)};
$CLJS.B7=function(a,b){return"string"===typeof b?$CLJS.k3(a,$CLJS.d0(b)):$CLJS.Ae(b)?$CLJS.j3(a,b):null};C7=function(a,b,c){return $CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Wj.g(d),c)},a))?$CLJS.Pm.h(function(d){var e=$CLJS.Dm.h(d,b);return $CLJS.F.h($CLJS.Wj.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
D7=function(a){var b=function(){var d=$CLJS.e0.g(a),e=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.X2,null,$CLJS.Z2,null,$CLJS.Q2,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.pL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.lk,A7(a)],null),function(){var d=$CLJS.r7(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.nQ,d],null):null}(),function(){var d=$CLJS.y7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.RQ,d],null):null}(),function(){var d=
x7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.QR,d],null):null}(),function(){var d=$CLJS.kR.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.XO,d],null):null}()]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,c,$CLJS.m(b)?function(){var d=$CLJS.V2.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}():function(){var d=$CLJS.Gk.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}()],null)};
$CLJS.E7=function E7(a){switch(arguments.length){case 2:return E7.h(arguments[0],arguments[1]);case 3:return E7.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.E7.h=function(a,b){return $CLJS.E7.j(a,-1,b)};$CLJS.E7.j=function(a,b,c){return $CLJS.t4.j(a,b,c)};$CLJS.E7.v=3;
$CLJS.F7=function F7(a){switch(arguments.length){case 2:return F7.h(arguments[0],arguments[1]);case 3:return F7.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.F7.h=function(a,b){return $CLJS.F7.j(a,-1,b)};$CLJS.F7.j=function(a,b,c){return $CLJS.T6.j(a,b,c)};$CLJS.F7.v=3;$CLJS.y7=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);
G7=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.w7=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);H7=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$sa=new $CLJS.N(null,"earliest","earliest",-1928154382);x7=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);ata=new $CLJS.N(null,"latest","latest",24323665);$CLJS.V3.o(null,$CLJS.FQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Oi.g(b),$CLJS.U3(c,new $CLJS.k(null,2,[$CLJS.RQ,$CLJS.Oi,$CLJS.QR,Wsa],null)),a],null)});var v7=!1,I7=function I7(a,b){var d=$CLJS.b4(a,G7.g(b));a=$CLJS.m(G7.g(d))?I7.h?I7.h(a,d):I7.call(null,a,d):d;a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.V);return $CLJS.Qm.j(b,$CLJS.V,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.O3.o(null,$CLJS.hW,function(a,b,c){return A7(c)});$CLJS.O3.o(null,$CLJS.FQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.RQ);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.y7,d):c;return $CLJS.P3.j(a,b,c)});$CLJS.l3.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});
$CLJS.l3.o(null,$CLJS.FQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d),f=$CLJS.M.h(e,$CLJS.HG),g=$CLJS.M.h(e,$CLJS.CG),l=$CLJS.M.h(e,$CLJS.QR),n=$CLJS.M.h(e,$CLJS.lk);d=$CLJS.M.h(e,$CLJS.nQ);var q=$CLJS.M.h(e,$CLJS.XO),u=$CLJS.M.h(e,$CLJS.RQ),v=z7(a,b,c),x=$CLJS.fn.l;f=new $CLJS.k(null,2,[$CLJS.rl,$CLJS.hW,$CLJS.I3,f],null);e=$CLJS.TI.g(e);b=$CLJS.m(e)?e:$CLJS.n3.j(a,b,c);b=x.call($CLJS.fn,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.TI,b],null),$CLJS.m(n)?new $CLJS.k(null,1,[$CLJS.lk,
n],null):null,$CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null,$CLJS.m(u)?new $CLJS.k(null,1,[$CLJS.y7,u],null):null,$CLJS.m(l)?new $CLJS.k(null,1,[x7,l],null):null,$CLJS.m(q)?new $CLJS.k(null,1,[$CLJS.kR,q],null):null]));d=$CLJS.m(d)?$CLJS.G5(b,d):b;return $CLJS.m(G7.g(d))?I7(a,d):d});
$CLJS.K3.o(null,$CLJS.hW,function(a,b,c,d){c=$CLJS.Qf(c);var e=$CLJS.M.h(c,$CLJS.TI),f=$CLJS.M.h(c,$CLJS.V),g=$CLJS.M.h(c,$CLJS.Wj),l=$CLJS.M.h(c,x7),n=$CLJS.M.h(c,$CLJS.b3),q=$CLJS.M.h(c,$CLJS.kR),u=$CLJS.M.h(c,$CLJS.j0);e=$CLJS.m(e)?e:"string"===typeof f?$CLJS.$3.h($CLJS.KG,f):$CLJS.p.g(f);$CLJS.F.h(d,$CLJS.o3)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.b4(a,q),$CLJS.m(q)?a=(0,$CLJS.Ca)($CLJS.Nu($CLJS.TI.g($CLJS.q3.j(a,b,q)),$CLJS.m0,"")):(u=$CLJS.B7(a,u),a=$CLJS.n3.D(a,b,u,d))):a=null,n=$CLJS.m(a)?
a:$CLJS.m(n)?n:$CLJS.r7(c)):n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.lG($CLJS.Nu($CLJS.ui(g),"-"," ")),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.P6(l,c),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):n});
$CLJS.K3.o(null,$CLJS.FQ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Qf(e);e=$CLJS.M.h(f,$CLJS.QR);var g=$CLJS.M.h(f,$CLJS.nQ),l=$CLJS.M.h(f,$CLJS.RQ);f=$CLJS.M.h(f,$CLJS.XO);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.b3,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.Wj,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,x7,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.kR,f):e;return $CLJS.m(e)?$CLJS.n3.D(a,b,e,d):$CLJS.VH("[Unknown Field]")});
$CLJS.L3.o(null,$CLJS.hW,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.L3.o(null,$CLJS.FQ,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=z7(a,b,c);return $CLJS.m(c)?$CLJS.M3.j(a,b,c):"unknown_field"});
$CLJS.Q3.o(null,$CLJS.hW,function(a,b,c){return $CLJS.fn.l($CLJS.G([function(){var d=$CLJS.tj($CLJS.Q3,$CLJS.Pj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.h($CLJS.e0.g(c),$CLJS.X2)?function(){var d=$CLJS.e4.g(c);return $CLJS.m(d)?(d=$CLJS.k3(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.k0,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.TI,$CLJS.V.g(d)],null)],null):null):null}():null]))});$CLJS.s4.o(null,$CLJS.FQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.RQ.g(b)});
$CLJS.s4.o(null,$CLJS.hW,function(a){return $CLJS.y7.g(a)});
$CLJS.r4.o(null,$CLJS.FQ,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ie($CLJS.VV,b),e=$CLJS.Nm.j(H7,$CLJS.lk,$CLJS.CG)(c);c=$CLJS.U.l(c,$CLJS.RQ,b,$CLJS.G([$CLJS.lk,d?$CLJS.il:e,H7,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,c,a],null)}b=H7.g(c);c=$CLJS.m(b)?$CLJS.Dm.h($CLJS.U.j(c,$CLJS.lk,b),H7):c;c=$CLJS.Dm.h(c,$CLJS.RQ);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FQ,c,a],null)});
$CLJS.r4.o(null,$CLJS.hW,function(a,b){return $CLJS.m(b)?$CLJS.U.j(a,$CLJS.y7,b):$CLJS.Dm.h(a,$CLJS.y7)});$CLJS.t4.o(null,$CLJS.FQ,function(a,b,c){return $CLJS.E7.j(a,b,z7(a,b,c))});
$CLJS.t4.o(null,$CLJS.hW,function(a,b,c){if($CLJS.ki.h($CLJS.e0.g(c),$CLJS.S2)){a=$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(c);b=null==c?null:$CLJS.fP.g(c);if(null==b)var d=null;else try{var e=$CLJS.SJ.g($CLJS.Wk.g(b));if($CLJS.m(e)){var f=$CLJS.Qf(e),g=$CLJS.M.h(f,$sa),l=$CLJS.M.h(f,ata),n=$CLJS.Usa.l($CLJS.G([$CLJS.n7.g(g),$CLJS.n7.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.mt.h?$CLJS.mt.h(1,n):$CLJS.mt.call(null,1,n))?$CLJS.uz:$CLJS.m($CLJS.mt.h?$CLJS.mt.h(31,n):$CLJS.mt.call(null,31,n))?$CLJS.wz:$CLJS.m($CLJS.mt.h?
$CLJS.mt.h(365,n):$CLJS.mt.call(null,365,n))?$CLJS.Yk:$CLJS.jl}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.OH(a,$CLJS.SJ)?$CLJS.Vqa:$CLJS.OH(a,$CLJS.OI)?$CLJS.Uqa:$CLJS.OH(a,$CLJS.XK)?$CLJS.Tqa:$CLJS.Lg;d=$CLJS.m(d)?C7(e,$CLJS.Pj,d):e;return $CLJS.m($CLJS.y7.g(c))?C7(d,$CLJS.v3,$CLJS.y7.g(c)):d}return $CLJS.Lg});
$CLJS.S6.o(null,$CLJS.FQ,function(a){var b=null==a?null:$CLJS.EH(a);b=null==b?null:$CLJS.QR.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.rl,$CLJS.Q6,$CLJS.G([$CLJS.z3,function(c,d){return z7(c,d,a)}]))});$CLJS.S6.o(null,$CLJS.hW,function(a){var b=null==a?null:x7.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.rl,$CLJS.Q6,$CLJS.G([$CLJS.z3,$CLJS.ag(a)]))});$CLJS.R6.o(null,$CLJS.FQ,function(a,b){return $CLJS.dW(a,$CLJS.cW,$CLJS.G([$CLJS.QR,b]))});
$CLJS.R6.o(null,$CLJS.hW,function(a,b){return $CLJS.cW(a,x7,b)});$CLJS.T6.o(null,$CLJS.FQ,function(a,b,c){return $CLJS.F7.j(a,b,z7(a,b,c))});
$CLJS.T6.o(null,$CLJS.hW,function(a,b,c){b=$CLJS.Qf(c);c=$CLJS.M.h(b,$CLJS.lk);var d=$CLJS.M.h(b,$CLJS.fP),e=$CLJS.M.h(b,$CLJS.Kl);if($CLJS.ki.h($CLJS.e0.g(b),$CLJS.S2)){var f=function(){var n=null==a?null:$CLJS.W3($CLJS.i3(a));n=null==n?null:$CLJS.wF.g(n);return null==n?null:$CLJS.Ie(n,$CLJS.QR)}(),g=$CLJS.Ju(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk,$CLJS.El],null)),l=$CLJS.s7(b);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),
y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<y){var Q=$CLJS.be(x,I),Y=B,aa=Q;Q=t7(Q,l)?$CLJS.U.j(aa,$CLJS.v3,!0):aa;Y.add(Q);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf(function(){var I=H;return t7(H,l)?$CLJS.U.j(I,$CLJS.v3,!0):I}(),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Gb(function(){if($CLJS.m(f)){var q=$CLJS.qk.g(g);return $CLJS.m(q)?$CLJS.Yl.g(g):q}return f}())?null:$CLJS.OH(e,$CLJS.ll)?$CLJS.r($CLJS.psa):
$CLJS.OH(c,$CLJS.El)&&!$CLJS.OH(e,$CLJS.Qj)?$CLJS.r($CLJS.osa):null)}return $CLJS.Lg});$CLJS.bW.o(null,$CLJS.FQ,function(a){return a});
$CLJS.bW.o(null,$CLJS.hW,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.e0);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.pL()),$CLJS.lk,$CLJS.Nm.h($CLJS.lk,$CLJS.CG)(a)],null),a=$CLJS.I3.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OP,b,a],null);case "source/expressions":return u7(a);case "source/breakouts":return $CLJS.Ie(a,$CLJS.NL)?u7(a):D7(a);default:return D7(a)}});