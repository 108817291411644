var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var sV,tV,lV,BV,qV,fla;$CLJS.mV=function(a,b){a=lV(a,b);return $CLJS.m(a)?a:$CLJS.nl};$CLJS.nV=function(a,b){$CLJS.dj.D($CLJS.DH,$CLJS.qG,a,b)};$CLJS.oV=function(a,b,c){a=$CLJS.ry.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.pV=function(a,b){return $CLJS.oV(a,b,null)};sV=function(){$CLJS.jL(qV,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Gt],null),$CLJS.Pe($CLJS.Le,$CLJS.r(rV))))};
tV=function(){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,2,[$CLJS.wk,$CLJS.C,$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(rV))};
$CLJS.uV=function(a,b){var c=$CLJS.Oi.h("mbql.clause",$CLJS.ui(a));$CLJS.jL(c,b);$CLJS.Ie($CLJS.r(rV),a)||$CLJS.dj.j(rV,$CLJS.kf,a);return null};$CLJS.vV=function(a,b,c){$CLJS.uV(a,c);$CLJS.CL.o(null,a,function(){return b});return null};$CLJS.yV=function(a,b){$CLJS.R.D(wV,xV,a,b)};$CLJS.AV=function(a,b){$CLJS.R.D(wV,zV,a,b)};
lV=function lV(a,b){return $CLJS.F.h(a,$CLJS.nl)?null:$CLJS.F.h(b,$CLJS.nl)?null:$CLJS.OH(a,b)?b:$CLJS.OH(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:lV.h?lV.h(d,e):lV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};BV=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.CV=new $CLJS.N(null,"short","short",1928760516);qV=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.DV=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);fla=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var rV=$CLJS.cj.g($CLJS.oi);$CLJS.FH(rV,fla,function(){sV();return $CLJS.jL(BV,tV())});sV();$CLJS.jL(BV,tV());$CLJS.jL($CLJS.EL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,qV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mm,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,BV],null)],null));
var zV=function zV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return zV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
zV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.At,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null)],null)],null)],null),b)],null)};zV.v=1;
zV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var xV=function xV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
xV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hq,$CLJS.yL],null)],null),b)};xV.v=1;xV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var wV=function wV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};wV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.xu)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.vV(b,c,a):$CLJS.uV(b,a)};wV.v=2;
wV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};